<template>
  <v-app>
    <div class="app-body">
      <div style="flex-grow: 1;">
        <htc-nav v-if="!$route.meta.hideNav" />
        <router-view></router-view>
      </div>
      <htc-footer v-if="!$route.meta.hideFooter" class="htc-footer" />
    </div>
    <v-overlay v-model="openLoading" persistent class="align-center justify-center" style="z-index: 9999;">
      <v-progress-circular :size="64" :width="6" indeterminate></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script setup lang="ts">
import HtcNav from '@/components/HtcNav.vue'
import HtcFooter from '@/components/HtcFooter.vue'

import { useLoading } from '@/store/loading'
import { storeToRefs } from 'pinia'
const { open: openLoading } = storeToRefs(useLoading())

</script>
<style>
#app {
  font-family: Montserrat, Noto Sans TC, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}
.app-body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.v-btn {
  text-transform: none;
}

.htc-btn {
  background-color: #A5CF4C;
  font-weight: bold;
  border: 1px solid #A5CF4C;
}

.htc-btn * {
  color: white;
}

.htc-btn:hover {
  background-color: white;
  border-color: #A5CF4C;
  border: 1px solid #A5CF4C;
}

.htc-btn:hover * {
  color: #A5CF4C;
}

.v-text-field.required .v-field-label::after {
  content: '*';
  color: red;
  margin-left: 5px;
}

.v-radio-group.required .v-label[id^="radio-group"]::after {
  content: '*';
  color: red;
  margin-left: 5px;
}

.v-file-input.required .v-field-label::after {
  content: '*';
  color: red;
  margin-left: 5px;
}

.v-checkbox.required .v-label::after {
  content: '*';
  color: red;
  margin-left: 5px;
}
</style>