<template>
    <v-container>
        <v-row class="mt-2">
            <template v-if="referral">
                <v-col cols="6">
                    <h2>{{ $t('job.recommend') }}</h2>
                </v-col>
                <v-col cols="6" class="d-flex flex-row-reverse">
                    <v-btn variant="plain" @click="onClickCancelReferral()">{{ $t('cancel') }}</v-btn>
                </v-col>
            </template>
            <v-col v-else cols="12">
                <h2>{{ $t('job.applying') }}</h2>
            </v-col>
        </v-row>
        <template v-for="job in jobs" :key="job.id">
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-item>
                            <v-card-title>{{ job.name }}</v-card-title>
                            <v-card-subtitle>
                                <htc-crumbs
                                    :items="[educationDisplay(job.educations), locationDisplay(job.locations), job.major, job.seniority]"></htc-crumbs>
                            </v-card-subtitle>
                        </v-card-item>
                        <v-card-text>
                            <h3 class="my-2">• {{ $t('job.description') }}</h3>
                            <span class="wrap">{{ job.description?.trim() }}</span>
                            <v-divider class="mt-4 mb-4"></v-divider>
                            <h3 class="my-2">• {{ $t('job.requirement') }}</h3>
                            <span class="wrap">{{ job.requirement?.trim() }}</span>
                        </v-card-text>
                    </v-card>

                </v-col>
            </v-row>
        </template>
        <v-row v-if="!loading.open">
            <v-col cols="12">
                <div v-if="mobile" class="mx-4">
                    <v-btn :to="{ name: 'apply', params: $route.params }" block class="htc-btn mt-2">{{ $t('apply.btn')
                    }}</v-btn>
                </div>
                <div v-else class="d-flex justify-end">
                    <template v-if="!referral">
                        <v-btn :to="{ name: 'job' }" class="htc-btn">{{ $t('back') }}</v-btn>
                    </template>
                    <v-btn :to="{ name: 'apply', params: $route.params }" class="htc-btn ml-2">{{ $t('apply.btn') }}</v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import HtcCrumbs from '@/components/HtcCrumbs.vue'

import { Education } from '@/api/education.api'
import { JobLocation } from '@/api/job-location.api'
import { onBeforeMount, reactive, ref } from 'vue'
import { useApplyForm } from '@/store/applyForm'
import { useLoading } from '@/store/loading'
import { getJob, Job } from '@/api/job.api'
import { useRouter } from 'vue-router'
import { toast } from 'vue3-toastify'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'

const { mobile } = useDisplay()
const store = useApplyForm()
const loading = useLoading()
const router = useRouter()
const i18n = useI18n()
const jobs = reactive<Job[]>([])
const referral = ref(false)

onBeforeMount(async () => {
    loading.show()
    await Promise.all([doGetJob(), doCheckReferralMode()])
    loading.hide()
})
const doGetJob = async () => {
    const data = store.getJobs()
    if (!data) return
    jobs.length = 0
    for (const id of data) {
        const { data: res } = await getJob(id)
        if (res.success) {
            if (!res.result) {
                toast.error('Job not found')
                return
            }
            store.setLocale(res.result.locale)
            jobs.push(res.result)
        } else {
            toast.error(res.message)
        }
    }
}
const doCheckReferralMode = async () => {
    referral.value = store.getReferralMode()
}
const educationDisplay = (educations: Education[]) => {
    if (educations.length === 0) {
        return i18n.t('job.list.noWorkExperience')
    } else {
        return educations.map(e => 'us' === i18n.locale.value ? e.engName : e.name).join(', ')
    }
}
const locationDisplay = (locations: JobLocation[]) => {
    if (!locations || locations.length === 0) {
        return i18n.t('job.list.noWorkExperience')
    } else {
        return locations.map(e => 'us' === i18n.locale.value ? e.engName : e.name).join(', ')
    }
}
const onClickCancelReferral = () => {
    store.clear()
    router.replace({ name: 'job' })
}
</script>
<style scoped>
.wrap {
    white-space: pre-line;
}
</style>
