import { ApiEntity, ApiResponse, https } from '.'

export interface SysPolicy extends ApiEntity {
    code: string
    title: string
    engTitle: string
    locale: string
    content: string
}

export class PolicyCode {
    public static COLLECTION = 'COLLECTION'
    public static SPECIFIC = 'SPECIFIC'
}

const path = 'policy'

export const getBy = (code: string, locale: string) => https.get<ApiResponse<SysPolicy>>([path, code, locale].join('/'))
