import { defineStore } from 'pinia'

export const useLoading = defineStore('loading', {
    state: () => ({
        open: false
    }),
    actions: {
        show() {
            this.open = true
        },
        hide() {
            this.open = false
        }
    }
})