import { ApiEntity, ApiResponse, https } from '.'

export interface ApplicationChannel extends ApiEntity {
	code: string
	name: string
	engName: string
}

const path = 'applicationChannels'
export const getApplicationChannels = () => https.get<ApiResponse<ApplicationChannel[]>>(path)
export const getApplicationChannelByCode = (code: string) => https.get<ApiResponse<ApplicationChannel>>([path, code].join('/'))
