// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  // defaults: {
  //   VBtn: {
  //     style: {
  //       color: '#FFF',
  //       backgroundColor: '#A5CF4C',
  //       fontWeight: 'bold'
  //     }
  //   }
  // }
})
