import { ApiResponse, ApiEntity, https } from '.'

export interface MilitaryStatus extends ApiEntity {
    name: string
    engName: string
    code: string
}
export interface CreateMilitaryStatusDto {
    name: string
    engName: string
    code: string
}
export interface UpdateMilitaryStatusDto {
    name: string
    engName: string
    code: string
}

const path = 'militaryStatuses'

export const getMilitaryStatuses = () => https.get<ApiResponse<MilitaryStatus[]>>(path)
export const getAllMilitaryStatuses = () => https.get<ApiResponse<MilitaryStatus[]>>([path, 'all'].join('/'))