<template>
    <v-container>
        <v-row>
            <v-col>
                <v-select   :readonly="store.referralMode"
                            :clearable="!store.referralMode"
                    v-model="applicationChannel" :items="applicationChannels" item-value="id"
                    :item-title="'us' === $i18n.locale ? 'engName' : 'name'"
                    :rules="[v => validate.required(v, 'apply.application.invalidate.applicationChannel')]"
                    :label="$t('apply.application.applicationChannel')" class="required">
                    <template #message="{ message }">{{ $t(message) }}</template>
                </v-select>
            </v-col>
            <v-col v-if="applicationChannelOther">
                <htc-text-field v-model="applicationChannelDesc"
                    :rules="[v => validate.required(v, 'apply.application.invalidate.applicationChannelDesc')]"
                    :label="$t('apply.application.other')" class="required" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
        </v-row>
        <v-row v-if="applicationChannel !== undefined && 'CH001' === applicationChannels.find(e => applicationChannel === e.id)?.code">
            <v-col cols="6">
                <htc-text-field :readonly="store.referralMode" :clearable="!store.referralMode" v-model="recommendToken"  max-length="20" :label="$t('apply.application.recommendToken')" />
            </v-col>
            <v-col cols="3">
                <htc-text-field :readonly="store.referralMode" :clearable="!store.referralMode" v-model="recommendEmpId" max-length="20" :label="$t('apply.application.recommenderId')" />
            </v-col>
            <v-col cols="3">
                <htc-text-field :readonly="store.referralMode" :clearable="!store.referralMode" v-model="recommendEmpName" max-length="20" :label="$t('apply.application.recommenderName')" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="mb-4">
                    <v-card-title>
                        <v-label :text="$t('apply.application.desiredJob')"></v-label>
                    </v-card-title>
                    <v-card-text>
                        <v-list lines="two" select-strategy="classic">
                            <template v-for="(item, idx) in jobs" :key="idx">
                                <v-list-item :ripple="false" elevation="1">
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <htc-crumbs
                                            :items="[educationDisplay(item.educations), item.major, item.seniority]" />
                                    </v-list-item-subtitle>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <htc-text-field v-model="expectSalary" max-length="50" :label="$t('apply.application.expectSalary')" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
import { ApplicationChannel, getApplicationChannels } from '@/api/application-channel.api'
import HtcTextField from '@/components/HtcTextField.vue'
import { onBeforeMount, reactive, watch } from 'vue'
import HtcCrumbs from '@/components/HtcCrumbs.vue'
import { useApplyForm } from '@/store/applyForm'
import { Education } from '@/api/education.api'
import { Job, getJob } from '@/api/job.api'
import validate from '@/utils/validate'
import { toast } from 'vue3-toastify'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

const store = useApplyForm()
const i18n = useI18n()

const { applicationChannel, applicationChannelOther, applicationChannelDesc, expectSalary, recommendToken, recommendEmpId, recommendEmpName } = storeToRefs(store)
const applicationChannels = reactive<ApplicationChannel[]>([])
const jobs = reactive<Job[]>([])

const doGetApplicationChannels = async () => {
    const { data: res } = await getApplicationChannels()
    if (res.success) {
        applicationChannels.length = 0
        res.result.forEach(e => applicationChannels.push(e))
    } else {
        toast.error(res.message)
    }
}
const doGetJob = async () => {
    const data = store.getJobs()
    if (!data) return
    jobs.length = 0
    for (const id of data) {
        const { data: res } = await getJob(id)
        if (res.success) {
            if (!res.result) {
                toast.error('Job not found')
                return
            }
            jobs.push(res.result)
        } else {
            toast.error(res.message)
        }
    }
}
onBeforeMount(async () => {
    await Promise.all([doGetApplicationChannels(), doGetJob()])
})
watch([applicationChannel, applicationChannelOther, applicationChannelDesc, recommendToken, recommendEmpId, recommendEmpName, expectSalary], () => store.setLocalStorage('application'))
watch(applicationChannel, (value) => {
    applicationChannelOther.value = 'CH005' === applicationChannels.find(e => value === e.id)?.code
})

const educationDisplay = (educations: Education[]) => {
    if (educations.length === 0) {
        return i18n.t('job.list.noWorkExperience')
    } else {
        return educations.map(e => 'us' === i18n.locale.value ? e.engName : e.name).join(', ')
    }
}
</script>
<style scoped>
.v-avatar {
    color: white;
    background-color: #A5CF4C;
    /* font-weight: bold; */
}
</style>
