<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <p class="text-body-2 ml-2 education-warn">{{ $t('apply.education.warn') }}</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-expansion-panels v-model="panel" variant="accordion" multiple>
                    <v-expansion-panel v-for="(item, index) in schoolExperiences" :key="index">
                        <v-expansion-panel-title @click="onPanelClick(index)">
                            <v-label>{{ `${$t('apply.education.title')} ${index + 1}` }}</v-label>
                            <v-spacer></v-spacer>
                            <v-icon v-if="schoolExperiences.length > 1" @click.stop="onDeleteClick(index)" color="#616264" class="mr-2">mdi-close-circle</v-icon>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col>
                                        <v-select v-model="item.education" :items="educations" item-value="id" :item-title="'us' === $i18n.locale ? 'engName' : 'name'" :label="$t('apply.education.qualifications')" clearable>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <htc-text-field v-model="item.school" :label="$t('apply.education.school')" clearable></htc-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-if="store.getLocale() !== 'CN'">
                                    <v-col>
                                        <v-autocomplete v-model="item.department" :items="schoolDepartments" item-value="id" :item-title="'us' === $i18n.locale ? 'engName' : 'name'"
                                            :label="$t('apply.education.deptClassification')" clearable></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-radio-group v-model="item.status" :label="$t('apply.education.status')" inline>
                                            <v-radio :label="$t('apply.education.graduated')" :value="GraduationStatus.GRADUATED"></v-radio>
                                            <v-radio :label="$t('apply.education.dropout')" :value="GraduationStatus.DROP_OUT"></v-radio>
                                            <v-radio :label="$t('apply.education.schooling')" :value="GraduationStatus.SCHOOLING"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <template v-if="item.status === GraduationStatus.DROP_OUT">
                                    <v-row>
                                        <v-col>
                                            <v-label>{{ $t('apply.education.dropOutDay') }}</v-label>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-else-if="item.status === GraduationStatus.SCHOOLING">
                                    <v-row>
                                        <v-col>
                                            <v-label>{{ $t('apply.education.schoolinDay') }}</v-label>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-else>
                                    <v-row>
                                        <v-col>
                                            <v-label>{{ $t('apply.education.graduationDay') }}</v-label>
                                        </v-col>
                                    </v-row>
                                </template>
                                <v-row>
                                    <v-col>
                                        <htc-text-field v-model="item.toDateYear" @update:focused="onFocusedYear(item, 'toDateYear', $event)" :label="$t('year')" placeholder="yyyy" type="number" clearable></htc-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-select v-model="item.toDateMonth" :items="months" :label="$t('month')" clearable></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn @click="onAddClick">{{ $t('create') }}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { SchoolDepartment, getSchoolDepartments } from '@/api/school-department.api'
import { SchoolExperienceForm, useApplyForm } from '@/store/applyForm'
import { Education, getEducations } from '@/api/education.api'
import { GraduationStatus } from '@/api/school-experience.api'
import { onBeforeMount, reactive, ref, watch } from 'vue'
import HtcTextField from '@/components/HtcTextField.vue'
import { toast } from 'vue3-toastify'

const store = useApplyForm()
const schoolExperiences = reactive(store.schoolExperiences)

const educations = reactive<Education[]>([])
const schoolDepartments = reactive<SchoolDepartment[]>([])
const months = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
const panel = reactive(schoolExperiences.map((e, i) => i))

onBeforeMount(async () => {
    await Promise.all([doGetEducationss(), doGetSchoolDepartments()])
})
watch(schoolExperiences, () => store.setLocalStorage('education'))

const doGetEducationss = async () => {
    const { data: res } = await getEducations()
    if (res.success) {
        educations.length = 0
        res.result.forEach(e => educations.push(e));
    } else {
        toast.error(res.message)
    }
}
const doGetSchoolDepartments = async () => {
    const { data: res } = await getSchoolDepartments()
    if (res.success) {
        schoolDepartments.length = 0
        res.result.forEach(e => schoolDepartments.push(e));
    } else {
        toast.error(res.message)
    }
}

const onFocusedYear = (item: SchoolExperienceForm, column: keyof { toDateYear?: number }, focus: boolean) => {
    if (!item[column] && focus) {
        item[column] = new Date().getFullYear()
    }
}
const onPanelClick = (index: number) => {
    if (panel.indexOf(index) > -1) {
        panel.splice(panel.indexOf(index), 1)
    } else {
        panel.push(index)
    }
}
const onDeleteClick = (index: number) => {
    if (schoolExperiences.length > 1) {
        schoolExperiences.splice(index, 1)
        if (panel.indexOf(index) > -1) panel.splice(panel.indexOf(index), 1)
    }
}
const onAddClick = () => {
    if (schoolExperiences.length >= 5) return
    schoolExperiences.push({})
    panel.push(schoolExperiences.length - 1)
}
</script>
<style scoped>
.v-btn {
    color: #FFFFFF;
    background-color: #A5CF4C;
}
.education-warn {
    color: #E53935;
}
.education-warn::before {
    color: #E53935;
    content: '*';
}
</style>