<template>
    <v-container>
        <v-form ref="form">
            <v-card>
                <v-tabs v-model="currentTab" bg-color="#A5CF4C">
                    <v-tab :value="tabNames[0]">{{ $t('personal-info.personal.title') }}
                        <v-icon v-if="alert.personal" color="red">mdi-alert-circle</v-icon>
                    </v-tab>
                    <v-tab :value="tabNames[1]">{{ $t('personal-info.education.title') }}
                        <v-icon v-if="alert.education" color="red">mdi-alert-circle</v-icon>
                    </v-tab>
                    <v-tab :value="tabNames[2]">{{ $t('personal-info.experience.title') }}
                        <v-icon  v-if="alert.experience" color="red">mdi-alert-circle</v-icon>
                    </v-tab>
                    <v-tab :value="tabNames[3]">{{ $t('personal-info.application.title') }}
                        <v-icon v-if="alert.application" color="red">mdi-alert-circle</v-icon>
                    </v-tab>
                    <v-tab :value="tabNames[4]">{{ $t('personal-info.other.title') }}
                        <v-icon v-if="alert.other" color="red">mdi-alert-circle</v-icon>
                    </v-tab>
                </v-tabs>
                <v-card-text>
                    <v-window v-model="currentTab">
                        <v-window-item :value="tabNames[0]">
                            <personal-view v-model="vm"></personal-view>
                        </v-window-item>
                        <v-window-item :value="tabNames[1]">
                            <education-view v-model="vm"></education-view>
                        </v-window-item>
                        <v-window-item :value="tabNames[2]">
                            <experience-view v-model="vm"></experience-view>
                        </v-window-item>
                        <v-window-item :value="tabNames[3]">
                            <application-view v-model="vm"></application-view>
                        </v-window-item>
                        <v-window-item :value="tabNames[4]">
                            <other-view v-model="vm"></other-view>
                        </v-window-item>
                    </v-window>
                </v-card-text>
            </v-card>
            <v-container fluid>
                <v-row>
                    <v-col class="d-flex justify-left">
                        <v-btn v-if="showBack" @click="onClickBack()" :disabled="isLoading" elevation="0" class="htc-btn">{{ $t('personal-info.back') }}</v-btn>
                    </v-col>
                    <v-col class="d-flex justify-center">
                        <v-btn @click="onClickSave()" :disabled="isLoading" elevation="0" class="htc-btn">{{ $t('save') }}</v-btn>
                    </v-col>
                    <v-col class="d-flex justify-end">
                        <v-btn v-if="showNext" @click="onClickNext()" :disabled="isLoading" elevation="0" class="htc-btn">{{ $t('personal-info.next') }}</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </v-container>
</template>
<script setup lang="ts">
import PersonalView from './PersonalView.vue'
import EducationView from './EducationView.vue'
import ExperienceView from './ExperienceView.vue'
import ApplicationView from './ApplicationView.vue'
import OtherView from './OtherView.vue'

import { SapPersonalInfoVm, getValidator, get, update } from '@/api/sap-personal-info.api'
import { SapSchoolExperience } from '@/api/sap-school-experience.api'
import { onBeforeMount, reactive, ref, watch} from 'vue'
import { useLoading } from '@/store/loading'
import { useRouter } from 'vue-router'
import { toast } from 'vue3-toastify'
import { storeToRefs } from 'pinia'
import { VForm } from 'vuetify/components'

const router = useRouter()
const loading = useLoading()
const { open: isLoading } = storeToRefs(loading)

const tabNames = ['personal', 'education', 'experience', 'application', 'other']
const currentTab = ref()
const showBack = ref(false)
const showNext = ref(false)
const form = ref<VForm>()
const vm = reactive({} as SapPersonalInfoVm)
const alert = ref({
    personal: false,
    education: false,
    experience: false,
    application: false,
    other: false
})
watch(currentTab, (t) => {
    let idx = tabNames.indexOf(t)
    showBack.value = idx > 0
    showNext.value = idx < tabNames.length - 1
})

onBeforeMount(async () => {
    loading.show()
    await Promise.all([
        doGetSapPersonalInfo()
    ])
    loading.hide()
})

const doGetSapPersonalInfo = async () => {
    const token = router.currentRoute.value.params.token.toString()
    const { data: res } = await get(token)
    if (res.success) {
        if (!res.result || !res.result.id) {
            // not found
            router.replace({ name: 'job' })
            return
        }
        Object.assign(vm, res.result)
        // 學歷必填
        if (!vm.schoolExperiences || vm.schoolExperiences.length == 0) {
            vm.schoolExperiences.push({ seq: 1 } as SapSchoolExperience)
        }
    } else {
        router.replace({ name: 'job' })
    }
}

const onClickSave = async () => {
    let validator = getValidator()
    let valid = validator.validate(vm)
    Object.assign(alert.value, validator.alert)
    if (!valid) {
        form.value?.validate()
        return
    }

    loading.show()
    // remove empty work experience
    vm.schoolExperiences = vm.schoolExperiences.filter(item => {
        // keep index is 0 data
        let isEmpty = Object.values(item).every(val => {
            return !val
        })
        return !isEmpty
    })
    // remove empty work experience
    vm.workExperiences = vm.workExperiences.filter(item => {
        // keep index is 0 data
        let isEmpty = Object.values(item).every(val => {
            return !val
        })
        return !isEmpty
    })
    // remove empty work experience
    vm.recommenders = vm.recommenders.filter(item => {
        // keep index is 0 data
        let isEmpty = Object.values(item).every(val => {
            return !val
        })
        return !isEmpty
    })
    // sequence
    vm.schoolExperiences.forEach((item, index) => {
        item.seq = index + 1
    })
    vm.workExperiences.forEach((item, index) => {
        item.seq = index + 1
    })
    vm.recommenders.forEach((item, index) => {
        item.seq = index + 1
    })
    // do update
    const { data: resp } = await update(vm)
    if (resp.success) {
        toast.success(resp.message)
    } else {
        toast.error(resp.message)
    }
    loading.hide()
}

const onClickNext = () => {
    currentTab.value = tabNames[tabNames.indexOf(currentTab.value) + 1]
}

const onClickBack = () => {
    currentTab.value = tabNames[tabNames.indexOf(currentTab.value) - 1]
}

</script>

<style scoped>
.v-btn {
    color: white;
    background-color: #A5CF4C;
    font-weight: bold;
}

.hover {
    color: #A5CF4C;
    background-color: white;
    border-color: #A5CF4C;
    border: 1px solid;
}

.bg-basil {
    background-color: #FFFBE6 !important;
}

.text-basil {
    color: #356859 !important;
}
</style>