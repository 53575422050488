<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-label class="required">{{ $t('personal-info.education.duration') }}</v-label>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-expansion-panels v-model="panels" variant="accordion" multiple>
                    <v-expansion-panel v-for="(item, index) in vm.schoolExperiences" :key="index">
                        <v-expansion-panel-title @click="onClickPanel(index)">
                            <span>{{ [$t('personal-info.education.title'), index + 1].join(' ') }}</span>
                            <v-spacer></v-spacer>
                            <v-icon v-if="vm.schoolExperiences.length > 1" @click.stop="remove(index)" color="#616264"
                                class="mr-2">mdi-close-circle</v-icon>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-select v-model="item.education" :items="educations" item-value="id"
                                            item-title="name" :label="$t('personal-info.education.qualifications')"
                                            class="required"
                                            :rules="[v => validate.required(v, 'personal-info.education.invalidate.qualifications')]"
                                            return-object clearable>
                                            <template #message="{ message }">{{ $t(message) }}</template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="item.fromDate" :label="$t('personal-info.education.fromDate')"
                                        type="date"
                                            class="required"
                                            :rules="[v => validate.required(v, 'personal-info.education.invalidate.fromDate')]"
                                            clearable>
                                            <template #message="{ message }">{{ $t(message) }}</template></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="item.toDate" :label="$t('personal-info.education.toDate')"
                                        type="date"
                                            class="required"
                                            :rules="[v => validate.required(v, 'personal-info.education.invalidate.toDate')]"
                                            clearable>
                                            <template #message="{ message }">{{ $t(message) }}</template></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <htc-text-field v-model="item.school" :label="$t('personal-info.education.school')"
                                            class="required"
                                            :rules="[v => validate.required(v, 'personal-info.education.invalidate.school')]"
                                            clearable>
                                            <template #message="{ message }">{{ $t(message) }}</template></htc-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-autocomplete v-model="item.department" :items="departments" item-title="name"
                                            item-value="id" :label="$t('personal-info.education.deptClassification')"
                                            class="required"
                                            :rules="[v => validate.required(v, 'personal-info.education.invalidate.deptClassification')]"
                                            return-object clearable>
                                            <template #message="{ message }">{{ $t(message) }}</template></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-label class="required">{{ $t('personal-info.education.graduationDate')
                                        }}</v-label>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-radio-group v-model="item.status" :label="$t('personal-info.education.status')"
                                            inline class="required"
                                            :rules="[v => validate.required(v, 'personal-info.education.invalidate.status')]">
                                            <template #message="{ message }">{{ $t(message) }}</template>
                                            <template v-for="item in status" :key="item.value">
                                                <v-radio :label="$t('personal-info.education.' + item.value.toLowerCase())"
                                                    :value="item.value">
                                                </v-radio>
                                            </template>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn class="htc-btn" @click="add">{{ $t('create') }}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import HtcTextField from '@/components/HtcTextField.vue'
import validate from '@/utils/validate'

import { SapSchoolExperience } from '@/api/sap-school-experience.api'
import { onBeforeMount, computed, reactive, ref } from 'vue'
import { getSchoolDepartments } from '@/api/school-department.api'
import { SapPersonalInfoVm } from '@/api/sap-personal-info.api'
import { GraduationStatus } from '@/api/school-experience.api'
import { getEducations } from '@/api/education.api'
import { toast } from 'vue3-toastify'

const props = defineProps<{
    modelValue: SapPersonalInfoVm
}>()

onBeforeMount(async () => {
    await Promise.all([
        doGetEducations(),
        doGetSchoolDepartments(),
        doGetGraduationStatuses()
    ])
})

const educations = ref()
const departments = ref()
const status = ref()

const panels = reactive(props.modelValue.schoolExperiences.map((e, i) => i))
const vm = computed(() => {
    return props.modelValue
})
const doGetEducations = async () => {
    const { data: res } = await getEducations()
    if (res.success) {
        educations.value = res.result
    } else {
        toast.error(res.message)
    }
}
const doGetSchoolDepartments = async () => {
    const { data: res } = await getSchoolDepartments()
    if (res.success) {
        departments.value = res.result
    } else {
        toast.error(res.message)
    }
}
const doGetGraduationStatuses = () => {
    status.value = Object.keys(GraduationStatus).map(k => ({
        name: GraduationStatus[k as keyof typeof GraduationStatus],
        value: GraduationStatus[k as keyof typeof GraduationStatus]
    }))
}

const onClickPanel = (index: number) => {
    if (panels.indexOf(index) > -1) {
        panels.splice(panels.indexOf(index), 1)
    } else {
        panels.push(index)
    }
}
const remove = (index: number) => {
    if (vm.value.schoolExperiences.length > 1) {
        vm.value.schoolExperiences.splice(index, 1)
        if (panels.indexOf(index) > -1) panels.splice(panels.indexOf(index), 1)
    }
}
const add = () => {
    if (vm.value.schoolExperiences.length < 3) {
        vm.value.schoolExperiences.push({} as SapSchoolExperience)
        panels.push(vm.value.schoolExperiences.length - 1)
    }
}

</script>