import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pl-1 pr-1" }
const _hoisted_2 = {
  key: 0,
  class: "ml-1 mr-1 font-weight-medium"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, idx) => {
    return (_openBlock(), _createElementBlock("span", { key: idx }, [
      item
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("span", _hoisted_1, _toDisplayString(item), 1),
            ($props.items.length !== (idx + 1))
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, " • "))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}