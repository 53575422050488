<script lang="ts">
import { defineComponent } from 'vue'
import { VTextField } from 'vuetify/components'

/**
 * support to max-width for string type
 * @author stanford_chen.it
 * @props maxLength
 */
export default defineComponent({
    extends: VTextField,
    props: {
        maxLength: { type: [String, Number], required: false },
        required: { type: Boolean, required: false, default: false }
    },
    setup: VTextField.setup,
    watch: {
        modelValue(value) {
            if (this.$props.maxLength) {
                const maxLength = 'string' === typeof this.$props.maxLength ? parseInt(this.$props.maxLength) : this.$props.maxLength
                if ('string' === typeof value && value) {
                    if (value.length > maxLength) {
                        this.$emit('update:modelValue', value.substring(0, maxLength))
                    }
                }
            }
        }
    }
})
</script>
