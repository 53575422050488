import { ApiEntity } from '.'
import { Education } from './education.api'
import { SchoolDepartment } from './school-department.api'

export interface SchoolExperience extends ApiEntity {
    school: string
    department: SchoolDepartment
    education: Education
    status: GraduationStatus
    fromDate: number
    toDate: number
    seq: number
}
export enum GraduationStatus {
    GRADUATED = 'Graduated',	// 畢業
    DROP_OUT = 'Drop out', 		// 肄業
    SCHOOLING = 'Schooling' 	// 就學中
}
export interface CreateSchoolExperienceDto {
    school?: string
    department?: number
    education?: number
    status?: GraduationStatus
    fromDate?: number
    toDate?: number
    // seq?: number
}