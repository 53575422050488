export default {
  "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "keyWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關鍵字"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子信箱"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關閉"])},
  "nav": {
    "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["求才訊息"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意書"])}
  },
  "job": {
    "applying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您正在申請"])},
    "recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的朋友推薦了這個職缺"])},
    "requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任用條件"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作內容"])},
    "query": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["職缺查詢"])},
      "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*請搜尋後點選欲應徵的職缺，進行履歷投遞"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["職務類別"])},
      "employmentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["職缺性質"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作地點"])},
      "fullTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全職"])},
      "partTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兼職"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查詢"])}
    },
    "list": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作列表"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["職務代碼"])},
      "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["職務"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作地點"])},
      "noWorkExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不拘"])},
      "noLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無"])}
    },
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])}
  },
  "apply": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一步"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送出"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["應徵"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回主頁"])},
    "consent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料保護法告知與同意書（招募）"])},
      "exTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料為特定目的外利用之告知與同意書"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人已詳細閱讀以上同意書內文,理解並完全同意所有內容"])},
      "invalidate": {
        "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請勾選「本人已詳細閱讀以上同意書內文,理解並完全同意所有內容」"])}
      }
    },
    "personal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料"])},
      "chineseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文姓名"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文姓氏"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文名字"])},
      "englishName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英文姓名"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡電話"])},
      "invalidate": {
        "chineseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入中文姓名"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入中文姓氏"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入中文名字"])},
        "englishName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入英文姓名"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入電子信箱"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入行動電話"])},
        "emailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子信箱格式錯誤"])},
        "phoneFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行動電話格式錯誤"])}
      }
    },
    "education": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教育程度"])},
      "qualifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教育程度"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["學校"])},
      "deptClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["科系別"])},
      "graduationDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["畢業年月"])},
      "dropOutDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["肄業年月"])},
      "schoolinDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預計畢業年月"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["就學狀況"])},
      "graduated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["畢業"])},
      "dropout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["肄業"])},
      "schooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["就學中"])},
      "warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請填寫最高及次高學歷"])},
      "invalidate": {
        "graduationDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入格式為：西元年（yyyy）/月（MM）"])}
      }
    },
    "experience": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作經歷"])},
      "seniority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相關工作經驗年資"])},
      "noExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無"])},
      "belowOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一年以下"])},
      "aboveOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一年以上"])},
      "aboveTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二年以上"])},
      "aboveThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三年以上"])},
      "aboveFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四年以上"])},
      "aboveFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五年以上"])},
      "aboveSix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六年以上"])},
      "aboveSeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七年以上"])},
      "aboveEight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["八年以上"])},
      "aboveNine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["九年以上"])},
      "aboveTen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十年以上"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請從最近的工作經驗開始填寫"])},
      "onTheJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仍在職"])},
      "experienceCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作經驗／工讀經驗"])},
      "workExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作經驗"])},
      "partTimeExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工讀經驗"])},
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名稱"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作地點"])},
      "servingZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任職期間"])},
      "startYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["起（年）"])},
      "startMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["起（月）"])},
      "endYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迄（年）"])},
      "endMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迄（月）"])},
      "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任職時職務"])},
      "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["薪資待遇"])},
      "jobContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要工作內容"])},
      "reasonForLeaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["離職原因"])},
      "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["單位主管"])},
      "supervisorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主管姓名"])},
      "supervisorJobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["職稱"])},
      "supervisorPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡電話"])},
      "invalidate": {
        "servingZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入格式為：西元年（yyyy）/月（MM）"])}
      }
    },
    "application": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["應徵相關"])},
      "applicationChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["應徵資訊管道"])},
      "desiredJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["希望應徵職務"])},
      "expectSalary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期望待遇"])},
      "recommendToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦代碼"])},
      "recommenderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦員工編號"])},
      "recommenderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦員工姓名"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "invalidate": {
        "applicationChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇應徵資訊管道"])},
        "applicationChannelDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入應徵資訊管道"])}
      }
    },
    "other": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "isRequiredWorkPermit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否需向本國政府申請工作許可證？"])},
      "isRequiredWorkPermitDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（請於報到時，提供國民身份證或相關文件）"])},
      "hadViolatedIpRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有否因侵害智慧財產權、營業祕密或刑事責任而涉訟？"])},
      "hadViolatedIpRightDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請詳述如下"])},
      "recommender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦人"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請列舉三位能提供您的品德與能力等參考資料的人士(親友不包括在內)"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
        "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["職稱"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機關名稱及所在城市"])},
        "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡電話/Email"])}
      },
      "uploadHeadshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請上傳去背大頭照"])},
      "uploadAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請上傳履歷檔案"])},
      "uploadHeadshotDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大頭照檔案限用圖檔檔，容量大小限制 10MB"])},
      "uploadAttachmentDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["履歷檔案限用 WORD 或 PDF 檔，容量大小限制 10MB"])},
      "uploadPortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳檔案"])},
      "uploadPortfolioDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作品集、成績單...等，容量大小限制 10MB"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作品集連結或其他參考資料連結"])},
      "invalidate": {
        "isRequiredWorkPermit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇是否需向本國政府申請工作許可證？"])},
        "uploadHeadshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請上傳大頭照"])},
        "uploadAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請上傳履歷檔案"])}
      }
    }
  },
  "delete-resume": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請刪除履歷"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送出"])},
    "sendSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄送成功，請查收您的信箱"])},
    "invalidate": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入電子信箱"])},
      "emailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子信箱格式錯誤"])}
    },
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定要刪除所有履歷資料嗎？"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您好"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除履歷成功"])}
  },
  "personal-info": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一步"])},
    "consent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料保護法告知與同意書（招募）"])},
      "exTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料為特定目的外利用之告知與同意書"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人已詳細閱讀以上同意書內文,理解並完全同意所有內容"])},
      "invalidate": {
        "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請勾選「本人已詳細閱讀以上同意書內文,理解並完全同意所有內容」"])}
      }
    },
    "personal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料"])},
      "chineseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文姓名"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文姓氏"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文名字"])},
      "englishName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英文姓名"])},
      "gender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女"])}
      },
      "idNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份證字號或護照號碼"])},
      "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生日期"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國籍"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子信箱"])},
      "phoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡電話"])},
      "homePhoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住家電話"])},
      "contactPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵遞區號"])},
      "registerPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵遞區號"])},
      "contactAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通訊住址"])},
      "registerAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戶籍地址"])},
      "militaryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兵役"])},
      "militaryFromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役期(起)"])},
      "militaryToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役期(迄)"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行動電話"])},
      "invalidate": {
        "chineseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入中文姓名"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入中文姓氏"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入中文名字"])},
        "englishName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入英文姓名"])},
        "gender": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入性別"])}
        },
        "idNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入身份證字號或護照號碼"])},
        "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入出生日期"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入國籍"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入電子信箱"])},
        "phoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入連絡電話"])},
        "homePhoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入通訊電話"])},
        "contactPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入通訊住址郵遞區號"])},
        "registerPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入戶籍住址郵遞區號"])},
        "contactAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入通訊住址"])},
        "registerAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入戶籍地址"])},
        "militaryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入兵役"])},
        "militaryFromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入兵役日期(起)"])},
        "militaryToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入兵役日期(訖)"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入行動電話"])},
        "phoneNoFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡電話格式錯誤"])},
        "homePhoneNoFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通訊電話格式錯誤"])},
        "emailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子信箱格式錯誤"])},
        "phoneFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行動電話格式錯誤"])},
        "contactPostalCodeFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵遞區號格式錯誤"])},
        "registerPostalCodeFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵遞區號格式錯誤"])}
      }
    },
    "education": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教育程度"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["就學期間"])},
      "fromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自"])},
      "toDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至"])},
      "qualifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教育程度"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["學校"])},
      "deptClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["科系別"])},
      "graduationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["畢業年月"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["就學狀況"])},
      "graduated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["畢業"])},
      "drop out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["肄業"])},
      "schooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["就學中"])},
      "graduationDateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYYMM"])},
      "invalidate": {
        "qualifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入教育程度"])},
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入學校"])},
        "deptClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入科系別"])},
        "fromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入格式為：西元年（YYYY）月（MM）共六位"])},
        "fromDateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入格式為：西元年（YYYY）月（MM）共六位"])},
        "toDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入格式為：西元年（YYYY）月（MM）共六位"])},
        "toDateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入格式為：西元年（YYYY）月（MM）共六位"])},
        "graduationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入格式為：西元年（YYYY）月（MM）共六位"])},
        "graduationDateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入格式為：西元年（YYYY）月（MM）共六位"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入就學狀況"])}
      }
    },
    "experience": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作經歷"])},
      "seniority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相關工作經驗年資"])},
      "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請由最近一筆開始填寫，最多五筆"])},
      "noExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無"])},
      "belowOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一年以下"])},
      "aboveOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一年以上"])},
      "aboveTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二年以上"])},
      "aboveThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三年以上"])},
      "aboveFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四年以上"])},
      "aboveFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五年以上"])},
      "aboveSix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六年以上"])},
      "aboveSeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七年以上"])},
      "aboveEight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["八年以上"])},
      "aboveNine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["九年以上"])},
      "aboveTen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十年以上"])},
      "experienceCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作經驗／工讀經驗"])},
      "workExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作經驗"])},
      "partTimeExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工讀經驗"])},
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名稱"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作地點"])},
      "servingZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任職期間"])},
      "startYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["起（年）"])},
      "startMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["起（月）"])},
      "endYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迄（年）"])},
      "endMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迄（月）"])},
      "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任職時職務"])},
      "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["薪資待遇"])},
      "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["單位主管"])},
      "supervisorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主管姓名"])},
      "supervisorJobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["職稱"])},
      "supervisorPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡電話"])},
      "invalidate": {
        "seniority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入相關工作經驗年資"])}
      }
    },
    "application": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["應徵相關"])},
      "applicationChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["應徵資訊管道"])},
      "desiredJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["希望應徵職務"])},
      "expectSalary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期望待遇"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "invalidate": {
        "applicationChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇應徵資訊管道"])}
      }
    },
    "other": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "isIllnessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康情形：是否領有身心障礙手冊，具有慢性疾病、傳染性疾病或動過手術等？"])},
      "healthStatusDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請描述"])},
      "emergency": {
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急聯絡人-姓名"])},
        "relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急聯絡人-關係"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急聯絡人-住址"])},
        "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急聯絡人-電話"])}
      },
      "recommender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦人"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請列舉三位能提供您的品德與能力等參考資料的人士(親友不包括在內)"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
        "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["職稱"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機關名稱及所在城市"])},
        "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行動電話"])}
      },
      "isRequiredWorkPermit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否需向本國政府申請工作許可證？"])},
      "isRequiredWorkPermitDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（請於報到時，提供國民身份證或相關文件）"])},
      "hadViolatedIpRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有否因侵害智慧財產權、營業祕密或刑事責任而涉訟？"])},
      "hadViolatedIpRightDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請詳述如下"])},
      "uploadAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳履歷檔案"])},
      "uploadAttachmentDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["履歷檔案限用 WORD 或 PDF 檔，容量大小限制 10MB"])},
      "uploadedHeadshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已上傳大頭照"])},
      "uploadHeadshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請上傳白色底的大頭照"])},
      "uploadHeadshotDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大頭照檔案限用圖檔(jpg, png)，容量大小限制 10MB"])},
      "invalidate": {
        "isRequiredWorkPermit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇是否需向本國政府申請工作許可證？"])},
        "isIllnessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇健康情形"])},
        "emergency": {
          "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入緊急聯絡人-姓名"])},
          "relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入緊急聯絡人-關係"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入緊急聯絡人-住址"])},
          "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入緊急聯絡人-電話"])}
        },
        "uploadAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請上傳履歷檔案"])},
        "uploadHeadshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請上傳白色底的大頭照"])}
      }
    }
  },
  "resume": {
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作品集連結或其他參考資料連結"])},
    "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["履歷檔案"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳檔案"])}
  },
  "policy": {
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私權政策"])},
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法律聲明"])},
    "cookie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie聲明"])}
  },
  "referral": {
    "recommender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦人"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["應徵者姓名"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["應徵者電子郵件"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦理由"])},
    "phoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["應徵者電話"])}
  }
}