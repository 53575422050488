<template>
    <v-container>
        <v-card>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <p class="text-h5">{{ $t('delete-resume.title') }}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="mail" :label="$t('email')"
                            :rules="[v => validate.required(v, 'delete-resume.invalidate.email'),
                                     v =>    validate.email(v, 'delete-resume.invalidate.emailFormat')]"
                            type="email" clearable>
                            <template #message="{ message }">{{ $t(message) }}</template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn @click="onSubmitClick" :disabled="submitDisabled">
                            <v-progress-circular v-if="submitDisabled" size="20" indeterminate></v-progress-circular>
                            <span v-else>{{ $t('delete-resume.submit') }}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>
<script setup lang="ts">
import validate from '@/utils/validate'

import { send } from '@/api/delete-resume.api'
import { useLoading } from '@/store/loading'
import { toast } from 'vue3-toastify'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

const loading = useLoading()
const i18n = useI18n()

const mail = ref()
const submitDisabled = ref(false)

const onSubmitClick = async () => {
    if (!validate.required(mail.value) || !validate.email(mail.value)) return

    loading.show()
    submitDisabled.value = true
    const { data: res } = await send(mail.value)
    submitDisabled.value = false
    if (res.success) {
        toast.success(i18n.t('delete-resume.sendSuccess'))
        mail.value = undefined
    } else {
        toast.error(res.message)
    }
    loading.hide()
}
</script>
<style scoped>
.v-btn {
    color: #FFFFFF;
    background-color: #A5CF4C;
}
</style>
