import { ApiResponse, ApiEntity, https } from '.'
import { Job } from './job.api'
import { Country } from './country.api'
import { Seniority } from './resume.api'
import { MilitaryStatus } from './military-status.api'
import { ApplicationChannel } from './application-channel.api'
import { SapWorkExperience, SapWorkExperienceDto, toSapWorkExperienceDto } from './sap-work-experience.api'
import { SapSchoolExperience, SapSchoolExperienceDto, toSapSchoolExperienceDto } from './sap-school-experience.api'
import { SapRecommender } from './sap-recommender.api'

// Edit, Detail
export interface SapPersonalInfoVm extends ApiEntity {
    // name: string
    lastName: string
    firstName: string
    engName: string
    gender: Gender
    idNo: string
    birthDate?: Date
    mail: string
    country: Country
    contactPostalCode: string
    contactAddress: string
    phoneNo: string
    registerPostalCode: string
    registerAddress: string
    homePhoneNo: string
    militaryStatus: MilitaryStatus
    militaryFromDate?: Date
    militaryToDate?: Date
    graduateDate: string
    schoolExperiences: SapSchoolExperience[]
    seniority: Seniority
    workExperiences: SapWorkExperience[]
    applicationChannel: ApplicationChannel
    jobs: Job[]
    expectSalary: string
    recommendEmpId: string
    recommendEmpName: string
    needWorkPermit: boolean
    violatedIpRight: boolean
    violatedIpRightDesc: string
    illness: boolean
    healthStatus: string
    emergencyPerson: string
    emergencyRelation: string
    emergencyAddress: string
    emergencyTel: string
    recommenders: SapRecommender[]

    headshots: File[]
    uploadedHeadshots: { [id: number]: string } | undefined

    // Resume
    links: string[]
    cv: { [id: number]: string }
    portfolio: { [id: number]: string }
}

export enum Gender {
    M = 'Male',
    F = 'Female'
}

interface SapPersonalInfoDto {
    id: number
    // name: string
    lastName: string
    firstName: string
    engName: string
    gender: Gender
    idNo: string
    birthDate?: Date
    mail: string
    country: number
    contactAddress: string
    phoneNo: string
    registerAddress: string
    homePhoneNo: string
    militaryStatus: number
    militaryFromDate?: Date
    militaryToDate?: Date
    graduateDate: string
    schoolExperiences: SapSchoolExperienceDto[]
    seniority: Seniority
    workExperiences: SapWorkExperienceDto[]
    applicationChannel: number
    jobs: number[]
    expectSalary: string
    recommendEmpId: string
    recommendEmpName: string
    needWorkPermit: boolean
    violatedIpRight: boolean
    violatedIpRightDesc: string
    illness: boolean
    healthStatus: string
    emergencyPerson: string
    emergencyRelation: string
    emergencyAddress: string
    emergencyTel: string
    recommenders: SapRecommender[]
}

const toDto: (vm: SapPersonalInfoVm) => SapPersonalInfoDto = (vm: SapPersonalInfoVm) => ({
    id: vm.id,
    lastName: vm.lastName,
    firstName: vm.firstName,
    engName: vm.engName,
    gender: vm.gender,
    idNo: vm.idNo,
    birthDate: vm.birthDate,
    mail: vm.mail,
    country: vm.country?.id,
    contactPostalCode: vm.contactPostalCode,
    contactAddress: vm.contactAddress,
    phoneNo: vm.phoneNo,
    registerPostalCode: vm.registerPostalCode,
    registerAddress: vm.registerAddress,
    homePhoneNo: vm.homePhoneNo,
    militaryStatus: vm.militaryStatus?.id,
    militaryFromDate: vm.militaryFromDate,
    militaryToDate: vm.militaryToDate,
    graduateDate: vm.graduateDate,
    schoolExperiences: vm.schoolExperiences?.map(s => toSapSchoolExperienceDto(s)),
    seniority: vm.seniority,
    workExperiences: vm.workExperiences?.map(w => toSapWorkExperienceDto(w)),
    applicationChannel: vm.applicationChannel?.id,
    jobs: vm.jobs?.map(j => j.id),
    expectSalary: vm.expectSalary,
    recommendEmpId: vm.recommendEmpId,
    recommendEmpName: vm.recommendEmpName,
    needWorkPermit: vm.needWorkPermit,
    violatedIpRight: vm.violatedIpRight,
    violatedIpRightDesc: vm.violatedIpRightDesc,
    illness: vm.illness,
    healthStatus: vm.healthStatus,
    emergencyPerson: vm.emergencyPerson,
    emergencyRelation: vm.emergencyRelation,
    emergencyAddress: vm.emergencyAddress,
    emergencyTel: vm.emergencyTel,
    recommenders: vm.recommenders
})

const Validator = {
    alert: {
        personal: false,
        education: false,
        experience: false,
        application: false,
        other: false
    },
    validate(vm: SapPersonalInfoVm) {
        this.alert.personal = false
        this.alert.education = false
        this.alert.experience = false
        this.alert.application = false
        this.alert.other = false

        // personal
        this.alert.personal = this.alert.personal || !vm.lastName
        this.alert.personal = this.alert.personal || !vm.firstName
        this.alert.personal = this.alert.personal || !vm.engName
        this.alert.personal = this.alert.personal || !vm.gender
        this.alert.personal = this.alert.personal || !vm.idNo
        this.alert.personal = this.alert.personal || !vm.birthDate
        this.alert.personal = this.alert.personal || !vm.mail
        this.alert.personal = this.alert.personal || !vm.country
        this.alert.personal = this.alert.personal || !vm.contactPostalCode
        this.alert.personal = this.alert.personal || !vm.contactAddress
        this.alert.personal = this.alert.personal || !vm.phoneNo
        this.alert.personal = this.alert.personal || !vm.registerPostalCode
        this.alert.personal = this.alert.personal || !vm.registerAddress
        this.alert.personal = this.alert.personal || !vm.homePhoneNo
        this.alert.personal = this.alert.personal || !vm.militaryStatus
        if ('FINISHED' === vm.militaryStatus?.code) {
            this.alert.personal = this.alert.personal || !vm.militaryFromDate
            this.alert.personal = this.alert.personal || !vm.militaryToDate
        }

        // education
        //this.alert.education = this.alert.education || !vm.graduateDate
        this.alert.education = vm.schoolExperiences === undefined || vm.schoolExperiences.length === 0
        this.alert.education = this.alert.education || vm.schoolExperiences.filter(se => se.education).length !== vm.schoolExperiences.length
        this.alert.education = this.alert.education || vm.schoolExperiences.filter(se => se.school).length !== vm.schoolExperiences.length
        this.alert.education = this.alert.education || vm.schoolExperiences.filter(se => se.department).length !== vm.schoolExperiences.length
        this.alert.education = this.alert.education || vm.schoolExperiences.filter(se => se.fromDate).length !== vm.schoolExperiences.length
        this.alert.education = this.alert.education || vm.schoolExperiences.filter(se => se.toDate).length !== vm.schoolExperiences.length

        // experience
        this.alert.experience = this.alert.experience || !vm.seniority
        this.alert.experience = this.alert.experience || !vm.workExperiences

        // application
        this.alert.application = this.alert.application || !vm.applicationChannel
        this.alert.application = this.alert.application || !vm.jobs
        // this.alert.application = this.alert.application || !vm.expectSalary

        // other
        this.alert.other = this.alert.other || vm.needWorkPermit === undefined || vm.needWorkPermit === null
        this.alert.other = this.alert.other || vm.violatedIpRight === undefined || vm.violatedIpRight === null
        // this.alert.other = this.alert.other || !vm.violatedIpRightDesc
        this.alert.other = this.alert.other || vm.illness === undefined || vm.illness === null
        // this.alert.other = this.alert.other || !vm.healthStatus
        this.alert.other = this.alert.other || !vm.emergencyPerson
        this.alert.other = this.alert.other || !vm.emergencyRelation
        this.alert.other = this.alert.other || !vm.emergencyAddress
        this.alert.other = this.alert.other || !vm.emergencyTel
        // this.alert.other = this.alert.other || !vm.recommenders
        // headshots
        if (!vm.headshots) {
            if (vm.uploadedHeadshots) {
                if (Object.entries(vm.uploadedHeadshots).length === 0) {
                    this.alert.other = true
                }
            } else {
                this.alert.other = true
            }
        }

        return !this.alert.personal &&
                !this.alert.education &&
                !this.alert.experience &&
                !this.alert.application &&
                !this.alert.other
    }
}

const path = 'sapPersonalInfos'

export const get = (token: string) => https.get<ApiResponse<SapPersonalInfoVm>>([path, token].join('/'))
export const update = (vm: SapPersonalInfoVm) => {
    const formData = new FormData()
    if (vm.headshots) {
        vm.headshots.forEach(e => formData.append('headshots', e))
    }
    formData.append('vo', new Blob([JSON.stringify(toDto(vm))], { type: 'application/json' }))
    return https.post<ApiResponse<void>>(path, formData)
}
export const getValidator = () => { return Object.create(Validator) }