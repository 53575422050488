import { ApiEntity, ApiResponse, https } from '.'

export interface JobFunction extends ApiEntity {
    name: string
    engName: string
}

export interface CreateJobFunctionDto {
    name: string
    engName: string
}

export interface UpdateJobFunctionDto {
    name: string
    engName: string
}

const path = 'jobFunctions'

export const getJobFunctions = () => https.get<ApiResponse<JobFunction[]>>(path)
