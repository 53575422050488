<template>
  <v-container v-bind="$attrs">
    <template v-if="mobile">
      <v-footer class="d-flex justify-center">
        <div>
          <v-card-actions class="d-flex justify-center">
            <!-- <v-btn @click="onPrivacyClick" variant="plain">{{ $t('policy.privacy') }}</v-btn> -->
            <v-btn v-for="(link, index) in links" :key="index" :href="link.href" variant="plain" target="_blank">
              {{ $t(link.title) }}
            </v-btn>
          </v-card-actions>
          <p class="d-flex justify-center">{{ corp }}</p>
          <p class="d-flex justify-center">宏達國際電子股份有限公司 | 統一編號16003518</p>
        </div>
      </v-footer>
    </template>
    <template v-else>
      <v-footer class="d-flex flex-column">
        <div class="d-flex w-100">
          <div class="text-left w-100">
            <!-- <v-btn @click="onPrivacyClick" variant="plain">{{ $t('policy.privacy') }}</v-btn> -->
            <v-btn v-for="(link, index) in links" :key="index" :href="link.href" variant="plain" target="_blank">
              {{ $t(link.title) }}
            </v-btn>
          </div>
          <!-- <v-btn v-for="(icon, index) in icons" :key="index" class="mx-4" :icon="icon.name" :href="link.href" variant="plain" size="small" target="_blank"></v-btn> -->
          <div class="text-right w-100">
            <p>{{ corp }}</p>
            <p> 宏達國際電子股份有限公司 | 統一編號16003518</p>
          </div>
        </div>
      </v-footer>
    </template>
  </v-container>
  <!-- <htc-dialog v-bind="dialog.props" :title="$t('policy.privacy')" :cancel-button="$t('close')">
    <h1 class="text-h6 mb-2">{{ collectionPolicy?.title }}</h1>
    <p class="policy-content">{{ collectionPolicy?.content }}</p>
    <v-divider class="my-4" />
    <h1 class="text-h6 mb-2">{{ specificPolicy?.title }}</h1>
    <p class="policy-content">{{ specificPolicy?.content }}</p>
  </htc-dialog> -->
</template>

<script setup lang="ts">
// import HtcDialog from '@/components/HtcDialog.vue'
// import { useDialog } from '@/utils/dialog'
// import { PolicyCode, SysPolicy, getBy } from '@/api/sys-policy.api'
import { ref, onBeforeMount } from 'vue'
// import { toast } from 'vue3-toastify'
import { useDisplay } from 'vuetify'

interface Link {
  title: string
  href: string
}
interface Icon {
  name: string
  href: string
}

const { mobile } = useDisplay()
const links = ref([] as Link[])
const icons = ref([] as Icon[])
const corp = ref('')
// const collectionPolicy = ref<SysPolicy>()
// const specificPolicy = ref<SysPolicy>()
// const dialog = useDialog()

onBeforeMount(async () => {
  await Promise.all(
    [
      doGetCorporation(),
      // doGetCollectionPolicy(), 
      // doGetSpecificPolicy(), 
      doGetLinks(), 
      doGetIcons()
    ]
  )
})

const doGetCorporation = () => {
  corp.value = '© 2011-' + new Date().getFullYear() + ' HTC Corporation'
}
// const doGetCollectionPolicy = async () => {
//   const { data: res } = await getBy(PolicyCode.COLLECTION)
//   if (res.success) {
//     collectionPolicy.value = res.result
//   } else {
//     toast.error(res.message)
//   }
// }
// const doGetSpecificPolicy = async () => {
//   const { data: res } = await getBy(PolicyCode.SPECIFIC)
//   if (res.success) {
//     specificPolicy.value = res.result
//   } else {
//     toast.error(res.message)
//   }
// }

const doGetLinks = async () => {
  links.value.push({
    title: 'policy.privacy',
    href: 'https://www.htc.com/tw/terms/privacy/'
  })
  links.value.push({
    title: 'policy.term',
    href: 'https://www.htc.com/tw/terms/htc/'
  })
  links.value.push({
    title: 'policy.cookie',
    href: 'https://www.htc.com/tw/terms/cookies/'
  })
}

const doGetIcons = async () => {
  icons.value.push({
    name: 'mdi-facebook',
    href: 'https://www.facebook.com/HTCTaiwan'
  })
  icons.value.push({
    name: 'mdi-instagram',
    href: 'https://www.instagram.com/htc_taiwan/'
  })
  icons.value.push({
    name: 'mdi-discord',
    href: 'https://discord.com/invite/QAtV6xXT5t'
  })
  icons.value.push({
    name: 'mdi-youtube',
    href: 'https://www.youtube.com/user/HTCTaiwan'
  })
}

// const onPrivacyClick = () => {
//   dialog.open()
// }
</script>
<style scoped>
.v-footer {
  background-color: #F4F4F4
}

p {
  font-family: Montserrat, Noto Sans TC, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #9b9b9b;
}

.policy-content {
  white-space: pre-line;
  color: #444;
}
</style>