<template>
    <v-container fluid>
        <v-row>
            <v-col cols="6" md="3">
                <htc-text-field v-model="vm.lastName" :label="$t('personal-info.personal.lastName')" class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.lastName')]" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template></htc-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <htc-text-field v-model="vm.firstName" :label="$t('personal-info.personal.firstName')" class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.firstName')]" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template></htc-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <htc-text-field v-model="vm.engName" :label="$t('personal-info.personal.englishName')" class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.englishName')]" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <htc-text-field v-model="vm.idNo" :label="$t('personal-info.personal.idNo')" class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.idNo')]" clearable><template
                        #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-radio-group v-model="vm.gender" :label="$t('personal-info.personal.gender.title')" inline
                    class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.gender.title')]"><template
                        #message="{ message }">{{ $t(message) }}</template>
                    <template v-for="item in genders" :key="item.value">
                        <v-radio :label="$t('personal-info.personal.gender.' + item.value.toLowerCase())"
                            :value="item.value"></v-radio>
                    </template>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <htc-text-field :model-value="dateToString(vm.birthDate)"
                    @update:model-value="vm.birthDate = stringToDate($event)"
                    :label="$t('personal-info.personal.birthDate')" type="date" class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.birthDate')]" clearable><template
                        #message="{ message }">{{ $t(message) }}</template></htc-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <htc-text-field v-model="vm.mail" :label="$t('personal-info.personal.email')" class="required" :rules="[v => validate.required(v, 'personal-info.personal.invalidate.email'),
                v => validate.email(v, 'personal-info.personal.invalidate.emailFormat')]" readonly
                    type="email"><template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4">
                <v-select v-model="vm.country" :items="countrys" item-title="name" item-value="id"
                    :label="$t('personal-info.personal.country')" return-object class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.country')]" clearable><template
                        #message="{ message }">{{ $t(message) }}</template>
                </v-select>
            </v-col>
            <v-col cols="12" md="4">
                <htc-text-field v-model="vm.phoneNo" :label="$t('personal-info.personal.phoneNo')" class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.phoneNo'),
                             v => validate.regex(v, /^\+?[0-9-]+$/, 'personal-info.personal.invalidate.phoneNoFormat')]" clearable><template
                        #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <htc-text-field v-model="vm.homePhoneNo" :label="$t('personal-info.personal.homePhoneNo')" class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.homePhoneNo'),
                             v => validate.regex(v, /^\+?[0-9-]+$/, 'personal-info.personal.invalidate.homePhoneNoFormat')]"
                    clearable><template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4" md="2">
                <htc-text-field v-model="vm.contactPostalCode" :label="$t('personal-info.personal.contactPostalCode')"
                    class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.contactPostalCode'),
                                v => validate.regex(v, /^(\d{3,5})$/, 'personal-info.personal.invalidate.contactPostalCodeFormat')]"
                    clearable><template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
            <v-col cols="20" md="10">
                <htc-text-field v-model="vm.contactAddress" :label="$t('personal-info.personal.contactAddress')"
                    class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.contactAddress')]"
                    clearable><template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4" md="2">
                <htc-text-field v-model="vm.registerPostalCode" :label="$t('personal-info.personal.registerPostalCode')"
                    class="required"
                    :rules="[   v => validate.required(v, 'personal-info.personal.invalidate.registerPostalCode'),
                                v => validate.regex(v, /^(\d{3,5})$/, 'personal-info.personal.invalidate.registerPostalCodeFormat')]"
                    clearable><template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
            <v-col cols="20" md="10">
                <htc-text-field v-model="vm.registerAddress" :label="$t('personal-info.personal.registerAddress')"
                    class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.registerAddress')]"
                    clearable><template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4">
                <v-select v-model="vm.militaryStatus" :items="militaryStatuses" item-title="name" item-value="id"
                    :label="$t('personal-info.personal.militaryStatus')" return-object class="required"
                    :rules="[v => validate.required(v, 'personal-info.personal.invalidate.militaryStatus')]"
                    clearable><template #message="{ message }">{{ $t(message) }}</template>
                </v-select>
            </v-col>
            <v-col cols="12" md="4">
                <htc-text-field :model-value="dateToString(vm.militaryFromDate)"
                    @update:model-value="vm.militaryFromDate = stringToDate($event)"
                    :class="needMilitaryDate ? 'required' : ''"
                    :rules="needMilitaryDate? [v => validate.required(v, 'personal-info.personal.invalidate.militaryFromDate')] : []"
                    :label="$t('personal-info.personal.militaryFromDate')" type="date" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <htc-text-field :model-value="dateToString(vm.militaryToDate)"
                    @update:model-value="vm.militaryToDate = stringToDate($event)"
                    :class="needMilitaryDate ? 'required' : ''"
                    :rules="needMilitaryDate? [v => validate.required(v, 'personal-info.personal.invalidate.militaryToDate')] : []"
                    :label="$t('personal-info.personal.militaryToDate')" type="date" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import HtcTextField from '@/components/HtcTextField.vue'
import validate from '@/utils/validate'

import { SapPersonalInfoVm, Gender } from '@/api/sap-personal-info.api'
import { getMilitaryStatuses } from '@/api/military-status.api'
import { stringToDate, dateToString } from '@/utils'
import { ref, onBeforeMount, computed } from 'vue'
import { getCountries } from '@/api/country.api'
import { toast } from 'vue3-toastify'

const props = defineProps<{
    modelValue: SapPersonalInfoVm
}>()

onBeforeMount(async () => {
    await Promise.all([
        doGetGenders(),
        doGetCountries(),
        doGetMilitaryStatuses()
    ])
})

const genders = ref()
const countrys = ref()
const militaryStatuses = ref()
const vm = computed(() => {
    return props.modelValue
})
const needMilitaryDate = computed(() => {
    return 'FINISHED' === vm.value.militaryStatus?.code
})
const doGetGenders = () => {
    genders.value = Object.keys(Gender).map(k => ({
        name: Gender[k as keyof typeof Gender],
        value: Gender[k as keyof typeof Gender]
    }))
}
const doGetCountries = async () => {
    const { data: res } = await getCountries()
    if (res.success) {
        countrys.value = res.result
    } else {
        toast.error(res.message)
    }
}
const doGetMilitaryStatuses = async () => {
    const { data: res } = await getMilitaryStatuses()
    if (res.success) {
        militaryStatuses.value = res.result
    } else {
        toast.error(res.message)
    }
}
</script>