import { DefineDateTimeFormat, createI18n } from 'vue-i18n'
import tw from '@/locales/zh-TW.json'
import en from '@/locales/en-US.json'

type MessageSchema = typeof tw

const i18n = createI18n<{message: MessageSchema, datetimeFormats: DefineDateTimeFormat}, 'tw' | 'us'>({
  legacy: false,
  locale: 'tw',
  fallbackLocale: 'us',
  globalInjection: true,
  messages: {
    'tw': tw,
    'us': en
  },
  datetimeFormats: {
    tw: {
      yyyyMM: {
        year: 'numeric',
        month: '2-digit',
      },
      short: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      },
      long: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h24'
      }
    },
    us: {
      yyyyMM: {
        year: 'numeric',
        month: '2-digit',
      },
      short: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      },
      long: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h24'
      }
    }
  }
})

export default i18n