<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-select v-model="vm.applicationChannel" :items="applicationChannels" item-title="name" item-value="id"
                    :label="$t('personal-info.application.applicationChannel')" class="required"
                    :rules="[v => validate.required(v, 'personal-info.application.invalidate.applicationChannel')]" return-object clearable>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="mb-4">
                    <v-card-title>
                        <v-label :text="$t('personal-info.application.desiredJob')"></v-label>
                    </v-card-title>
                    <v-card-text>
                        <v-list lines="two" select-strategy="classic">
                            <template v-for="(item, index) in vm.jobs" :key="index">
                                <v-list-item :ripple="false" elevation="1">
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <htc-crumbs :items="[item.major, item.seniority, item.description]"></htc-crumbs>
                                    </v-list-item-subtitle>
                                    <template #append>
                                        <v-avatar size="24" v-text="index + 1"></v-avatar>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <htc-text-field v-model="vm.expectSalary"
                    :label="$t('personal-info.application.expectSalary')"></htc-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
import HtcTextField from '@/components/HtcTextField.vue'
import HtcCrumbs from '@/components/HtcCrumbs.vue'
import validate from '@/utils/validate'

import { toast } from 'vue3-toastify'
import { ref, computed, onBeforeMount } from 'vue'
import { SapPersonalInfoVm } from '@/api/sap-personal-info.api'
import { getApplicationChannels } from '@/api/application-channel.api'

const props = defineProps<{
    modelValue: SapPersonalInfoVm
}>()

const applicationChannels = ref()
const vm = computed(() => {
    return props.modelValue
})

onBeforeMount(async () => {
    await Promise.all([
        doGetApplicationChannels()
    ])
})

const doGetApplicationChannels = async () => {
    const { data: res } = await getApplicationChannels()
    if (res.success) {
        applicationChannels.value = res.result
    } else {
        toast.error(res.message)
    }
}

</script>
<style scoped>
.v-avatar {
    color: white;
    background-color: #A5CF4C;
    /* font-weight: bold; */
}
</style>
