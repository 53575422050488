import { ApiEntity, ApiResponse, https } from '.'
import { Job } from './job.api'
import { ApplicationChannel } from './application-channel.api'
import { CreateRecommenderDto, Recommender } from './recommender.api'
import { CreateWorkExperienceDto, WorkExperience } from './work-experience.api'
import { CreateSchoolExperienceDto, SchoolExperience } from './school-experience.api'

export interface Resume extends ApiEntity {
    name: string
    engName: string
    mail: string
    phoneNo: string
    graduateDate: string
    applicationChannel: ApplicationChannel
    applicationChannelDesc: string
    recommendToken: string
    recommendEmpId: string
    recommendEmpName: string
    expectSalary: string
    needWorkPermit: boolean
    violatedIpRight: boolean
    violatedIpRightDesc: string
    seniority: Seniority
    links: string[]
    jobs: Job[]
    schoolExperiences: SchoolExperience[]
    workExperiences: WorkExperience[]
    recommenders: Recommender[]
}
export interface ResumeList extends ApiEntity {
    name: string
    engName: string
    mail: string
    phoneNo: string
    applicationChannel: ApplicationChannel
    jobs: Job[]
    schoolExperiences: SchoolExperience[]
    workExperiences: WorkExperience[]
    recommenders: Recommender[]
    favorite: boolean
}
export enum Seniority {
    NO_EXPERIENCE = 'No Experience',// 無
    BELOW_ONE = 'Below One',// 一年以下
    ABOVE_ONE = 'Above One',// 一年以上
    ABOVE_TWO = 'Above Two',// 二年以上
    ABOVE_THREE = 'Above Three',// 三年以上
    ABOVE_FOUR = 'Above Four',// 四年以上
    ABOVE_FIVE = 'Above Five',// 五年以上
    ABOVE_SIX = 'Above Six',// 六年以上
    ABOVE_SEVEN = 'Above Seven',// 七年以上
    ABOVE_EIGHT = 'Above Eight',// 八年以上
    ABOVE_NINE = 'Above Nine',// 九年以上
    ABOVE_TEN = 'Above Ten'// 十年以上
}
export interface CreateResumeDto {
    lastName: string
    firstName: string
    engName: string
    mail: string
    phoneNo: string
    applicationChannel: number
    applicationChannelDesc?: string
    recommendToken?: string
    recommendEmpId?: string
    recommendEmpName?: string
    expectSalary?: string
    needWorkPermit: boolean
    violatedIpRight?: boolean
    violatedIpRightDesc?: string
    seniority?: Seniority
    links: string[]
    jobs: number[]
    schoolExperiences: CreateSchoolExperienceDto[]
    workExperiences: CreateWorkExperienceDto[]
    recommenders: CreateRecommenderDto[]
}

export const getResume = (id: number) => https.get<ApiResponse<Resume>>(['resumes', id].join('/'))

export const createResume = (data: CreateResumeDto, attachments: File[], portfolio: File[]) => {
    const formData = new FormData()
    attachments.forEach(e => formData.append('attachments', e))
    portfolio.forEach(e => formData.append('portfolio', e))
    formData.append('vo', new Blob([ JSON.stringify(data) ], { type: 'application/json' }))
    return https.post<ApiResponse<void>>('resumes', formData)
}

export const getResumeAttachments = (id: number) => https.get<Blob>(['resumes', id, 'attachments'].join('/'), { responseType: 'blob' })