import { ApiEntity } from '.'

export interface SapWorkExperience extends ApiEntity {
    name: string
    partTime: boolean
    title: string
    function: string
    resignReason: string
    salary: string
    location: string
    fromDate?: Date
    toDate?: Date
    seq: number
}
export interface SapWorkExperienceDto {
    id: number
    name: string
    partTime: boolean
    title: string
    function: string
    resignReason: string
    salary: string
    location: string
    fromDate?: Date
    toDate?: Date
    seq: number
}
export const toSapWorkExperienceDto: (vm: SapWorkExperience) => SapWorkExperienceDto = (vm: SapWorkExperience) => ({
    id: vm.id,
    name: vm.name,
    partTime: vm.partTime,
    title: vm.title,
    function: vm.function,
    resignReason: vm.resignReason,
    salary: vm.salary,
    location: vm.location,
    fromDate: vm.fromDate,
    toDate: vm.toDate,
    seq: vm.seq
})