import axios from 'axios'

export interface ApiResponse<T> {
  success: boolean
  message: string
  result: T
}
export interface ApiEntity {
    id: number
    // creator: HTUser
    // modifier: HTUser
    // createTimestamp: number
    // modifyTimestamp: number
    // disabled: boolean
}
export interface ApiPageableResponse<T> extends ApiResponse<T> {
    totalPages: number
    total: number
}
export interface PageableDto {
    page: number
    size: number
    direction?: Direction
    sort?: string
}
export enum Direction {
    ASC = 'ASC',
    DESC = 'DESC'
}

export const https = axios.create({
    timeout: 5 * 60 * 1000,
    baseURL: process.env.VUE_APP_GATEWAY,
    headers: {
      Authorization: '', //TODO
    }
  })
