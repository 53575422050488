import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import JobView from '@/views/JobView.vue'
import AboutView from '@/views/AboutView.vue'
import ApplyView from '@/views/ApplyView.vue'
import ReferralView from '@/views/referral/ReferralView.vue'
import DetailView from '@/views/delete-resume/DetailView.vue'
import ApplyDeleteView from '@/views/delete-resume/ApplyView.vue'
import PersonalInfoEditView from '@/views/personal-info/EditView.vue'

declare module 'vue-router' {
  interface RouteMeta {
    nav?: {
      show: boolean | false
      label: string
    },
    hideNav?: boolean | false,
    hideFooter?: boolean | false
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'job'
  },
  {
    component: JobView,
    path: '/job',
    name: 'job',
    meta: {
      nav: {
        show: true,
        label: 'nav.job'
      }
    }
  },
  {
    component: AboutView,
    path: '/about',
    name: 'about'
  },
  {
    component: ApplyView,
    path: '/apply',
    name: 'apply'
  },
  {
    component: ApplyDeleteView,
    path: '/delete-resume/apply',
    name: 'delete-resume.apply',
    meta: {
      hideNav: true
    }
  },
  {
    path: '/delete-resume/detail',
    redirect: '/'
  },
  {
    component: DetailView,
    path: '/delete-resume/detail/:token',
    name: 'delete-resume.detail',
    meta: {
      hideNav: true
    }
  },
  // sap personal info,
  {
    path: '/personal-info/edit',
    redirect: '/'
  },
  {
    component: PersonalInfoEditView,
    path: '/personal-info/edit/:token',
    name: 'personal-info.edit',
    meta: {
      hideNav: true
    }
  },
  // referral recommend
  {
    path: '/referral',
    redirect: '/'
  },
  {
    component: ReferralView,
    path: '/referral/:token',
    name: 'referral',
    meta: {
      hideNav: true,
      hideFooter: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to) => {
  if (to.name === 'job') {
    const referralMode = localStorage.getItem('recruiting.resume.referralMode')
    const rm = referralMode ? JSON.parse(referralMode) as boolean : false
    if (rm) {
      return '/about'
    }
  }
  if (to.name === 'about' || to.name === 'apply') {
    const jobs = localStorage.getItem('recruiting.resume.jobs')
    const js = jobs ? JSON.parse(jobs) as number[] : undefined
    if (!js) {
      localStorage.setItem('recruiting.resume.referralMode', false.toString())
      return '/'
    }
  }
})
export default router
