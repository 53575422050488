<template>
  <v-container>
    <v-toolbar>
      <v-img max-width="50" max-height="50" class="ml-4 mr-4 mb-1" src="https://www.htc.com/static/app/htc_dist/public/fonts/htc-logo.svg"></v-img>
      <v-tab v-for="(tab, index) in tabs" :key="index" :to="tab.path" variant="plain">{{ $t(tab.label) }}</v-tab>
      <v-spacer></v-spacer>
      <v-btn :ripple="false" @click="change()">
        <v-icon>mdi-web</v-icon>
      </v-btn>
    </v-toolbar>
  </v-container>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

// tabs from routes 
interface Tab {
  name: string | symbol | undefined
  path: string
  label: string
}
const router = useRouter()
const tabs = reactive(router.options.routes.reduce((filtered: Tab[], option) => {
  if (option.meta?.nav?.show) {
    filtered.push({
      name: option.name,
      path: option.path,
      label: option.meta.nav.label
    })
  }
  return filtered;
}, []))

// i18n
const i18n = useI18n()
const change = () => {
  i18n.locale.value = i18n.availableLocales.filter(loc => {
    return i18n.locale.value !== loc
  })[0]
}
</script>

<style scoped>
.v-btn {
  font-size: 14px;
  font-weight: 1000;
}
</style>