<template>
  <v-container fluid>
    <template v-if="collectionPolicy">
      <v-row>
        <v-col cols="12">
          <h2>{{ 'us' === $i18n.locale ? collectionPolicy.engTitle : collectionPolicy.title }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card id="scroll-collection" class="overflow-y-auto" max-height="250">
            <v-card-text v-scroll:#scroll-collection="onCollectionPolicyScroll" v-html="collectionPolicy.content" />
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-if="specificPolicy">
      <v-row>
        <v-col cols="12">
          <h2>{{ 'us' === $i18n.locale ? specificPolicy.engTitle : specificPolicy.title }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card id="scroll-specific" class="overflow-y-auto" max-height="250">
            <v-card-text v-scroll:#scroll-specific="onSpecificPolicyScroll" v-html="specificPolicy.content" />
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-checkbox v-model="agree" :label="$t('apply.consent.agree')" :disabled="disabled || loading.open"
          :rules="[(v: boolean) => v || 'apply.consent.invalidate.agree']" density="compact"
          class="required checkbox-right">
          <template #message="{ message }">
            <span style="color: rgb(var(--v-theme-error));">{{ $t(message) }}</span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { SysPolicy, PolicyCode, getBy } from '@/api/sys-policy.api'
import { useApplyForm } from '@/store/applyForm'
import { onBeforeMount, watch, ref } from 'vue'
import { useLoading } from '@/store/loading'
import { toast } from 'vue3-toastify'
import { storeToRefs } from 'pinia'

const collectionPolicyReaded = ref<boolean>(false)
const specificPolicyReaded = ref<boolean>(false)
const collectionPolicy = ref<SysPolicy>()
const specificPolicy = ref<SysPolicy>()
const disabled = ref<boolean>(true)
const store = useApplyForm()
const loading = useLoading()

const { agree } = storeToRefs(store)

onBeforeMount(async () => {
  loading.show()
  await Promise.all([doGetPolicy()])
  loading.hide()
})
watch(agree, () => store.setLocalStorage('consent'))
watch([collectionPolicyReaded, specificPolicyReaded], ([cpr, spr]) => {
  disabled.value = !(cpr && spr)
})

const doGetPolicy = async () => {
  // Get Policy by code and locale
  const locale = store.getLocale()

  const { data: res } = await getBy(PolicyCode.COLLECTION, locale)
  if (res.success) {
    collectionPolicy.value = res.result

    const { data: resp } = await getBy(PolicyCode.SPECIFIC, locale)
    if (resp.success) {
      specificPolicy.value = resp.result
    } else {
      toast.error(resp.message)
    }
  } else {
    toast.error(res.message)
  }
}
const onCollectionPolicyScroll = (e: Event) => {
  collectionPolicyReaded.value = ((e.target as HTMLElement).scrollTop + 300) >= (e.target as HTMLElement).scrollHeight
}
const onSpecificPolicyScroll = (e: Event) => {
  specificPolicyReaded.value = ((e.target as HTMLElement).scrollTop + 300) >= (e.target as HTMLElement).scrollHeight
}
</script>
<style scoped>
.checkbox-right {
  justify-items: end;
}
</style>
