<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-radio-group v-model="vm.needWorkPermit" :label="$t('personal-info.other.isRequiredWorkPermit')"
                    :messages="'personal-info.other.isRequiredWorkPermitDesc'" inline class="required mb-4"
                    :rules="[v => validate.required(v, 'personal-info.other.invalidate.isRequiredWorkPermit')]">
                    <v-radio :label="$t('yes')" :value="true"></v-radio>
                    <v-radio :label="$t('no')" :value="false"></v-radio>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-radio-group v-model="vm.violatedIpRight" :label="$t('personal-info.other.hadViolatedIpRight')" inline
                    class="required"
                    :rules="[v => validate.required(v, 'personal-info.other.invalidate.hadViolatedIpRight')]">
                    <template #message="{ message }">{{ $t(message) }}</template>
                    <v-radio :label="$t('yes')" :value="true"></v-radio>
                    <v-radio :label="$t('no')" :value="false"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row v-if="vm.violatedIpRight">
            <v-col>
                <v-textarea v-model="vm.violatedIpRightDesc" :label="$t('personal-info.other.hadViolatedIpRightDesc')"
                    rows="5"></v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-radio-group v-model="vm.illness" :label="$t('personal-info.other.isIllnessDesc')" inline
                    class="required"
                    :rules="[v => validate.required(v, 'personal-info.other.invalidate.isIllnessDesc')]">
                    <template #message="{ message }">{{ $t(message) }}</template>
                    <v-radio :label="$t('yes')" :value="true"></v-radio>
                    <v-radio :label="$t('no')" :value="false"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row v-if="vm.illness">
            <v-col>
                <v-textarea v-model="vm.healthStatus" :label="$t('personal-info.other.healthStatusDesc')"
                    rows="5"></v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4">
                <htc-text-field v-model="vm.emergencyPerson" :label="$t('personal-info.other.emergency.person')"
                    class="required" :rules="[v => validate.required(v, 'personal-info.other.invalidate.emergency.person')]"
                    clearable>
                    <template #message="{ message }">{{ $t(message) }}</template></htc-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <htc-text-field v-model="vm.emergencyRelation" :label="$t('personal-info.other.emergency.relation')"
                    class="required"
                    :rules="[v => validate.required(v, 'personal-info.other.invalidate.emergency.relation')]" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <htc-text-field v-model="vm.emergencyTel" :label="$t('personal-info.other.emergency.tel')" class="required"
                    :rules="[v => validate.required(v, 'personal-info.other.invalidate.emergency.tel')]" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <htc-text-field v-model="vm.emergencyAddress" :label="$t('personal-info.other.emergency.address')"
                    class="required"
                    :rules="[v => validate.required(v, 'personal-info.other.invalidate.emergency.address')]" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template></htc-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <v-expansion-panels v-model="panels" variant="accordion" multiple>
                            <v-expansion-panel v-for="(item, index) in vm.recommenders" :key="index">
                                <v-expansion-panel-title @click="onClickPanel(index)">
                                    <span>{{ [$t('personal-info.other.recommender.title'), index + 1].join(' ') }}</span>
                                    <v-spacer></v-spacer>
                                    <v-icon v-if="vm.recommenders.length > 1" @click.stop="remove(index)" color="#616264"
                                        class="mr-2">mdi-close-circle</v-icon>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <v-container>
                                        <v-row>
                                            <v-col sm="3">
                                                <htc-text-field v-model="item.name"
                                                    :label="$t('personal-info.other.recommender.name')"
                                                    clearable></htc-text-field>
                                            </v-col>
                                            <v-col sm="3">
                                                <htc-text-field v-model="item.title"
                                                    :label="$t('personal-info.other.recommender.jobTitle')"
                                                    clearable></htc-text-field>
                                            </v-col>
                                            <v-col sm="3">
                                                <htc-text-field v-model="item.company"
                                                    :label="$t('personal-info.other.recommender.company')"
                                                    clearable></htc-text-field>
                                            </v-col>
                                            <v-col sm="3">
                                                <htc-text-field v-model="item.phoneNo"
                                                    :label="$t('personal-info.other.recommender.tel')"
                                                    clearable></htc-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn class="htc-btn" @click="add">{{ $t('create') }}</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
        <v-row v-if="vm.uploadedHeadshots && Object.entries(vm.uploadedHeadshots).length > 0">
            <v-col cols="6" md="3">
                <v-label class="mt-1">{{ $t('personal-info.other.uploadedHeadshot') }}</v-label>
            </v-col>
            <template v-for="[id, name] in Object.entries(vm.uploadedHeadshots)" :key="id">
                <v-col cols="6" md="9">
                    <v-btn size="small" variant="text" prepend-icon="mdi-download" @click="onClickDownload(parseInt(id))">
                        {{ name }}
                    </v-btn>
                    <v-icon size="small" icon="mdi-delete" color="red-accent-4" @click="onClickDelete(parseInt(id))"></v-icon>
                </v-col>
            </template>
        </v-row>
        <v-row v-else>
            <v-col cols="6" md="3">
                <v-label class="mt-1">{{ $t('personal-info.other.uploadHeadshot') }}</v-label>
            </v-col>
            <v-col cols="6" md="9">
                <v-file-input v-model="vm.headshots" :label="$t('personal-info.other.uploadHeadshot')"
                    messages="personal-info.other.uploadHeadshotDesc"
                    :rules="[v => validate.required(v, 'personal-info.other.invalidate.uploadHeadshot')]" clearable
                    accept="image/png, image/jpeg, image/bmp" density="compact" class="required">
                    <template #message="{ message }">{{ $t(message) }}</template>
                </v-file-input>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="3">
                <v-label class="mt-1">{{ $t('resume.cv') }}</v-label>
            </v-col>
            <v-col cols="6" md="9">
                <template v-if="vm.cv">
                    <v-btn v-for="[id, name] in Object.entries(vm.cv)" :key="id" size="small" variant="text" prepend-icon="mdi-download" @click="onClickDownload(parseInt(id))">
                        {{ name }}
                    </v-btn>
                </template>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="3">
                <v-label class="mt-1">{{ $t('resume.file') }}</v-label>
            </v-col>
            <v-col cols="6" md="9">
                <template v-if="vm.portfolio">
                    <v-btn v-for="[id, name] in Object.entries(vm.portfolio)" :key="id" size="small" variant="text" prepend-icon="mdi-download" @click="onClickDownload(parseInt(id))">
                        {{ name }}
                    </v-btn>
                </template>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="3">
                <v-label class="mt-1">{{ $t('resume.links') }}</v-label>
            </v-col>
            <v-col cols="6" md="9">
                <v-btn v-for="(item, index) in vm.links" :key="index" color="blue" size="small" variant="text" :href="item" target="_blank">
                    {{ item }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
import validate from '@/utils/validate'
import HtcTextField from '@/components/HtcTextField.vue'

import { ApiResponse } from '@/api'
import { toast } from 'vue3-toastify'
import { useLoading } from '@/store/loading'
import { reactive, computed } from 'vue'
import { Recommender } from '@/api/recommender.api'
import { SapPersonalInfoVm } from '@/api/sap-personal-info.api'
import { downloadApplicationData, deleteApplicationData } from '@/api/application-data.api'

const props = defineProps<{
    modelValue: SapPersonalInfoVm
}>()

const loading = useLoading()
const panels = reactive(props.modelValue.recommenders.map((e, i) => i))
const vm = computed(() => {
    return props.modelValue
})

const onClickPanel = (index: number) => {
    if (panels.indexOf(index) > -1) {
        panels.splice(panels.indexOf(index), 1)
    } else {
        panels.push(index)
    }
}
const remove = (index: number) => {
    if (vm.value.recommenders.length > 1) {
        vm.value.recommenders.splice(index, 1)
        if (panels.indexOf(index) > -1) panels.splice(panels.indexOf(index), 1)
    }
}
const add = () => {
    if (vm.value.recommenders.length < 3) {
        vm.value.recommenders.push({} as Recommender)
        panels.push(vm.value.recommenders.length - 1)
    }
}
const onClickDelete = async (id: number) => {
    loading.show()
    const { data: res } = await deleteApplicationData(id)
    if (res.success) {
        vm.value.uploadedHeadshots = undefined
    } else {
        toast.error(res.message)
    }
    loading.hide()
}
const onClickDownload = async (id: number) => {
    loading.show()
    const { data, headers } = await downloadApplicationData(id)
    if ('application/json' === data.type) {
        const res = JSON.parse(await data.text()) as ApiResponse<void>
        toast.error(res.message)
    } else if ('application/pdf' === data.type) {
        window.open(URL.createObjectURL(data), '_blank')
    } else {
        const filename = (headers['content-disposition'] as string | undefined)?.replace(/^.*filename="(.+?)";.*$/, '$1') ?? ''
        const link = document.createElement('a')
        link.href = URL.createObjectURL(data)
        link.download = decodeURI(filename)
        link.click()
        link.remove()
    }
    loading.hide()
}

</script>

<style scoped>
.htc-btn * {
    color: white;
    background-color: #A5CF4C;
    font-weight: bold;
}

.hover {
    color: #A5CF4C;
    background-color: white;
    border-color: #A5CF4C;
    border: 1px solid;
}
</style>
