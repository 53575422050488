<template>
    <span v-for="(item, idx) in items" :key="idx">
        <template v-if="item">
            <span class="pl-1 pr-1">
                {{ item }}
            </span>
            <span class="ml-1 mr-1 font-weight-medium" v-if="items.length !== (idx + 1)">
                •
            </span>
        </template>
    </span>
</template>

<script lang="ts">
export default ({
    props: {
        items: {
            type: Array,
            required: true
        }
    }
})
</script>