<template>
    <v-container>
        <!--
        <v-row>
            <v-col cols="12">
                <v-select v-model="seniority" :items="seniorities" :label="$t('apply.experience.seniority')" clearable></v-select>
            </v-col>
        </v-row>
        -->
        <v-row>
            <v-col>
                <v-label class="mb-2">{{ $t('apply.experience.desc') }}</v-label>
                <v-expansion-panels v-model="panel" variant="accordion" multiple>
                    <v-expansion-panel v-for="(item, index) in workExperiences" :key="index">
                        <v-expansion-panel-title @click="onPanelClick(index)">
                            <v-label>{{ [$t('apply.experience.title'), index + 1].join(' ') }}</v-label>
                            <v-spacer></v-spacer>
                            <v-icon v-if="workExperiences.length > 1" @click.stop="onDeleteClick(index)" color="#616264" class="mr-2">mdi-close-circle</v-icon>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-container>
                                <v-row v-if="store.getLocale() !== 'CN'">
                                    <v-col md="6" sm="12">
                                        <v-radio-group v-model="item.partTime" :label="$t('apply.experience.experienceCategory')" inline>
                                            <v-radio :label="$t('apply.experience.workExperience')" :value="false"></v-radio>
                                            <v-radio :label="$t('apply.experience.partTimeExperience')" :value="true"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="5" sm="6">
                                        <htc-text-field v-model="item.name" max-length="100" :label="$t('apply.experience.companyName')" clearable></htc-text-field>
                                    </v-col>
                                    <v-col md="4" sm="6">
                                        <htc-text-field v-model="item.location" max-length="100" :label="$t('apply.experience.location')" clearable></htc-text-field>
                                    </v-col>
                                    <v-col md="3" sm="6">
                                        <v-checkbox v-model="item.onTheJob" :label="$t('apply.experience.onTheJob')" hide-details></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-label>{{ $t('apply.experience.servingZone') }}</v-label>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col md="3" sm="6">
                                                <htc-text-field v-model="item.fromDateYear" :max="9999" @update:focused="onFocusedYear(index, 'fromDateYear', $event)" :label="$t('apply.experience.startYear')" placeholder="yyyy" type="number" clearable></htc-text-field>
                                            </v-col>
                                            <v-col md="3" sm="6">
                                                <v-select v-model="item.fromDateMonth" :items="months" :label="$t('apply.experience.startMonth')" clearable></v-select>
                                            </v-col>
                                            <template v-if="!item.onTheJob">
                                                <v-col md="3" sm="6">
                                                    <htc-text-field v-model="item.toDateYear" :max="9999" @update:focused="onFocusedYear(index, 'toDateYear', $event)" :label="$t('apply.experience.endYear')" placeholder="yyyy" type="number" clearable></htc-text-field>
                                                </v-col>
                                                <v-col md="3" sm="6">
                                                    <v-select v-model="item.toDateMonth" :items="months" :label="$t('apply.experience.endMonth')" clearable></v-select>
                                                </v-col>
                                            </template>

                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="6" sm="12">
                                        <htc-text-field v-model="item.title" max-length="100" :label="$t('apply.experience.jobTitle')" clearable></htc-text-field>
                                    </v-col>
                                    <v-col md="6" sm="12">
                                        <htc-text-field v-model="item.salary" max-length="100" :label="$t('apply.experience.salary')" clearable></htc-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <htc-text-area v-model="item.function" max-length="3000" :label="$t('apply.experience.jobContent')"></htc-text-area>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <htc-text-area v-model="item.resignReason" max-length="3000" :label="$t('apply.experience.reasonForLeaving')"></htc-text-area>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn @click="onAddClick">{{ $t('create') }}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
import HtcTextField from '@/components/HtcTextField.vue'
import HtcTextArea from '@/components/HtcTextArea.vue'
import { useApplyForm } from '@/store/applyForm'
import { reactive, ref, watch } from 'vue'

// const i18n = useI18n()
const store = useApplyForm()

// const { seniority } = storeToRefs(store)
const workExperiences = reactive(store.workExperiences)

// const seniorities = computed(() => [
//     { title: i18n.t('apply.experience.noExperience'), value: Seniority.NO_EXPERIENCE },
//     { title: i18n.t('apply.experience.belowOne')    , value: Seniority.BELOW_ONE },
//     { title: i18n.t('apply.experience.aboveOne')    , value: Seniority.ABOVE_ONE },
//     { title: i18n.t('apply.experience.aboveTwo')    , value: Seniority.ABOVE_TWO },
//     { title: i18n.t('apply.experience.aboveThree')  , value: Seniority.ABOVE_THREE },
//     { title: i18n.t('apply.experience.aboveFour')   , value: Seniority.ABOVE_FOUR },
//     { title: i18n.t('apply.experience.aboveFive')   , value: Seniority.ABOVE_FIVE },
//     { title: i18n.t('apply.experience.aboveSix')    , value: Seniority.ABOVE_SIX },
//     { title: i18n.t('apply.experience.aboveSeven')  , value: Seniority.ABOVE_SEVEN },
//     { title: i18n.t('apply.experience.aboveEight')  , value: Seniority.ABOVE_EIGHT },
//     { title: i18n.t('apply.experience.aboveNine')   , value: Seniority.ABOVE_NINE },
//     { title: i18n.t('apply.experience.aboveTen')    , value: Seniority.ABOVE_TEN },
// ])
const months = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
const panel = reactive(workExperiences.map((e, i) => i))

const onAddClick = () => {
    if (workExperiences.length >= 5) return
    workExperiences.push({})
    panel.push(workExperiences.length - 1)
}

const onDeleteClick = (index: number) => {
    if (workExperiences.length > 1) {
        workExperiences.splice(index, 1)
        if (panel.indexOf(index) > -1) panel.splice(panel.indexOf(index), 1)
    }
}

const onPanelClick = (index: number) => {
    if (panel.indexOf(index) > -1) {
        panel.splice(panel.indexOf(index), 1)
    } else {
        panel.push(index)
    }
}

const onFocusedYear = (index: number, column: keyof { fromDateYear: number | null, toDateYear: number | null }, focus: boolean) => {
    if (focus && !workExperiences[index][column]) {
        workExperiences[index][column] = new Date().getFullYear()
    }
}

watch([workExperiences], () => store.setLocalStorage('experience'))
</script>
<style scoped>
.v-btn {
    color: #FFFFFF;
    background-color: #A5CF4C;
}
</style>
