import { ApiEntity } from '.'
import { Country } from './country.api'
import { Education } from './education.api'
import { SchoolDepartment } from './school-department.api'
import { GraduationStatus } from './school-experience.api'

export interface SapSchoolExperience extends ApiEntity {
    country: Country
    school: string
    department: SchoolDepartment
    education: Education
    status: GraduationStatus
    fromDate: Date | undefined
    toDate: Date | undefined
    seq: number
}
export interface SapSchoolExperienceDto {
    id: number
    country: number
    school: string
    department: number
    education: number
    status: GraduationStatus
    fromDate: Date | undefined
    toDate: Date | undefined
    seq: number
}
export const toSapSchoolExperienceDto: (vm: SapSchoolExperience) => SapSchoolExperienceDto = (vm: SapSchoolExperience) => ({
    id: vm.id,
    country: vm.country?.id,
    school: vm.school,
    department: vm.department?.id,
    education: vm.education?.id,
    status: vm.status,
    fromDate: vm.fromDate,
    toDate: vm.toDate,
    seq: vm.seq
})