import { ApiEntity, https, ApiResponse, PageableDto, ApiPageableResponse } from '.'
import { JobGrade } from './job-grade.api'
import { Education } from './education.api'
import { JobCategory } from './job-category.api'
import { JobLocation } from './job-location.api'

export interface Job extends ApiEntity {
    name: string
    code: string
    category: JobCategory
    educations: Education[]
    major: string
    seniority: string
    requirement: string
    description: string
    locations: JobLocation[]
    grade: JobGrade;
    responsibility: boolean
    functions: JobLocation[]
    status: JobStatus
    publishDate: number
    closeDate: number
    locale: string
}

export enum JobStatus {
    PUBLISH = 'Publish', // 開啟中
    CLOSE = 'Close',   // 關閉中
    DRAFT = 'Draft',   // 草稿
    TODO = 'Todo'     // 待發佈日開啟
}
export interface JobList extends ApiEntity {
    name: string
    code: string
    major: string
    seniority: string
    educations: Education[]
    locations: JobLocation[]
}
export interface QueryJobDto extends PageableDto {
    categories?: number[]
    function?: number
    locations?: number[]
    keyword?: string
}

export const queryJobList = (dto: QueryJobDto) => https.post<ApiPageableResponse<JobList[]>>('jobs/query', dto)
export const getJob = (id: number) => https.get<ApiResponse<Job>>(['jobs', id].join('/'))