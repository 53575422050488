<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12">
                <div class="d-flex justify-start mb-6">
                    <h4 class="mr-2 ml-2">{{ $t('job.query.title') }}</h4>
                    <p class="mt-2" style="font-size: 11px;color: #A9A9A9;">{{ $t('job.query.tip') }}</p>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="6">
                <v-select v-model="categorys" :items="jobCategorys" item-value="id"
                    :item-title="'us' === $i18n.locale ? 'engName' : 'name'" :label="$t('job.query.type')" density="compact"
                    multiple chips clearable>
                </v-select>
            </v-col>
            <v-col cols="6" md="6">
                <v-select v-model="jobFunction" :items="jobFunctions" item-value="id"
                    :item-title="'us' === $i18n.locale ? 'engName' : 'name'" :label="$t('job.query.employmentType')"
                    density="compact" clearable>
                </v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="6">
                <v-select v-model="locations" :items="jobLocations" item-value="id"
                    :item-title="'us' === $i18n.locale ? 'engName' : 'name'" :label="$t('job.query.location')"
                    density="compact" multiple chips clearable>
                </v-select>
            </v-col>
            <v-col cols="6" md="6">
                <v-text-field @update:model-value="onChangeKeyword()" :loading="keyLoading" v-model="keyword"
                    :label="$t('keyWord')" density="compact" clearable></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-pagination v-if="mobile" v-model="page" :length="totalPage" total-visible="7" size="small" class="h-50"
                    density="compact"></v-pagination>
                <v-pagination v-else v-model="page" :length="totalPage" total-visible="10" class="h-50"
                    density="compact"></v-pagination>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-list lines="two" select-strategy="classic" min-height="20vh">
                    <template v-for="(item, idx) in dataset" :key="idx">
                        <v-list-item @click="onDetailClick(item)" :ripple="false" elevation="1" :disabled="keyLoading || disabled" :value="item">
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <htc-crumbs
                                    :items="[educationDisplay(item.educations), locationDisplay(item.locations), item.major, item.seniority]"></htc-crumbs>
                            </v-list-item-subtitle>
                        </v-list-item>
                    </template>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import HtcCrumbs from '@/components/HtcCrumbs.vue'

import { JobFunction, getJobFunctions } from '@/api/job-function.api'
import { JobCategory, getJobCategorys } from '@/api/job-category.api'
import { JobLocation, getJobLocations } from '@/api/job-location.api'
import { ref, reactive, onBeforeMount, watch } from 'vue'
import { JobList, queryJobList } from '@/api/job.api'
import { useApplyForm } from '@/store/applyForm'
import { Education } from '@/api/education.api'
import { useLoading } from '@/store/loading'
import { useRouter } from 'vue-router'
import { toast } from 'vue3-toastify'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'

const { mobile } = useDisplay()
const store = useApplyForm()
const loading = useLoading()
const router = useRouter()
const i18n = useI18n()

// data
const jobFunctions = reactive<JobFunction[]>([])
const jobCategorys = reactive<JobCategory[]>([])
const jobLocations = reactive<JobLocation[]>([])
const dataset = reactive<JobList[]>([])

// query conditions
const categorys = ref<number[]>([])
const jobFunction = ref()
const locations = ref<number[]>([])
const keyword = ref<string>('')
const keyUpTimer = ref()
const keyLoading = ref(false)

// pagination
const page = ref(1)
const totalPage = ref(0)
const perPage = ref(8)

// ui
const disabled = ref(false)

onBeforeMount(async () => {
    loading.show()
    await Promise.all([doGetJobFunctions(), doGetJobCategorys(), doGetJobLocations(), doQueryJobList()])
    loading.hide()
})
watch([categorys, jobFunction, locations, page, perPage], async () => {
    await doQueryJobList()
})
const doGetJobFunctions = async () => {
    const { data: res } = await getJobFunctions()
    if (res.success) {
        jobFunctions.length = 0
        res.result.forEach(e => jobFunctions.push(e))
    } else {
        toast.error(res.message)
    }
}
const doGetJobCategorys = async () => {
    const { data: res } = await getJobCategorys()
    if (res.success) {
        jobCategorys.length = 0
        res.result.forEach(e => jobCategorys.push(e))
    } else {
        toast.error(res.message)
    }
}
const doGetJobLocations = async () => {
    const { data: res } = await getJobLocations()
    if (res.success) {
        jobLocations.length = 0
        res.result.forEach(e => jobLocations.push(e))
    } else {
        toast.error(res.message)
    }
}
const doQueryJobList = async () => {
    disabled.value = true
    const { data: res } = await queryJobList({
        categories: categorys.value,
        function: jobFunction.value,
        locations: locations.value,
        keyword: keyword.value,
        page: page.value - 1,
        size: perPage.value
    })
    if (res.success) {
        dataset.length = 0
        totalPage.value = res.totalPages

        if (page.value > totalPage.value) {
            page.value = totalPage.value
        }
        if (page.value === 0) {
            page.value = 1
        }

        res.result.forEach(e => dataset.push(e))
    } else {
        toast.error(res.message)
    }
    // Proofing
    keyLoading.value = false
    disabled.value = false
}

const onChangeKeyword = async () => {
    keyLoading.value = true
    clearTimeout(keyUpTimer.value)
    keyUpTimer.value = setTimeout(async () => {
        await doQueryJobList()
        keyLoading.value = false
    }, 1000)
}
const onDetailClick = (item: JobList) => {
    store.setJobs([item.id])
    router.push({ name: 'about' })
}
const educationDisplay = (educations: Education[]) => {
    if (!educations || educations.length === 0) {
        return i18n.t('job.list.noWorkExperience')
    } else {
        return educations.map(e => 'us' === i18n.locale.value ? e.engName : e.name).join(', ')
    }
}
const locationDisplay = (locations: JobLocation[]) => {
    if (!locations || locations.length === 0) {
        return i18n.t('job.list.noWorkExperience')
    } else {
        return locations.map(e => 'us' === i18n.locale.value ? e.engName : e.name).join(', ')
    }
}
</script>

<style scoped>
.v-chip {
    color: white;
    background-color: #404040;
    /* font-weight: bold; */
}

.v-avatar {
    color: white;
    background-color: #A5CF4C;
    /* font-weight: bold; */
}
</style>