<template>
    <v-container fluid>
        <!-- <v-card>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <p class="text-h5">{{ $t('delete-resume.title') }}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="mail" :label="$t('email')" :rules="[v => validate.required(v, 'delete-resume.invalidate.email'),
                        v => validate.email(v, 'delete-resume.invalidate.emailFormat')]" type="email" clearable>
                            <template #message="{ message }">{{ $t(message) }}</template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn @click="onSubmitClick" :disabled="submitDisabled">
                            <v-progress-circular v-if="submitDisabled" size="20" indeterminate></v-progress-circular>
                            <span v-else>{{ $t('delete-resume.submit') }}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card> -->
    </v-container>
</template>
<script setup lang="ts">
import { getApplicationChannelByCode } from '@/api/application-channel.api'
import { getReferralHistory } from '@/api/referral.api'
import { useApplyForm } from '@/store/applyForm'
import { useLoading } from '@/store/loading'
import { useRouter } from 'vue-router'
import { onBeforeMount } from 'vue'
// import { useI18n } from 'vue-i18n'

const loading = useLoading()
const store = useApplyForm()
const router = useRouter()
// const i18n = useI18n()

onBeforeMount(async () => {
    loading.show()
    await Promise.all([
        doGetReferralHistory()
    ])
    loading.hide()
})
const doGetReferralHistory = async () => {
    const token = router.currentRoute.value.params.token.toString()
    const { data: res } = await getReferralHistory(token)
    if (res.success) {
        if (!res.result || !res.result.id) {
            router.replace({ name: 'job' })
            return
        }
        const { data: resp } = await getApplicationChannelByCode('CH001')
        if (resp.success) {
            store.clear()

            store.applicationChannel = resp.result.id
            store.recommendToken = res.result.id.toString().padStart(8, '0')
            store.recommendEmpId = res.result.recommender.userId
            store.recommendEmpName = res.result.recommender.usercname
            store.setLocalStorage('application')
            store.setReferralMode(true)

            store.jobs = [res.result.jobs[0].id]
            localStorage.setItem('recruiting.resume.jobs', JSON.stringify(store.jobs))
            router.replace({ name: 'about' })
        } else {
            router.replace({ name: 'job' })
        }
    } else {
        router.replace({ name: 'job' })
    }
}
</script>
<style scoped>
.v-btn {
    color: #FFFFFF;
    background-color: #A5CF4C;
}
</style>
