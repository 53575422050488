import { ApiEntity, ApiResponse, https } from '.'
import { HTUser } from './user.api'
import { Job } from './job.api'

export interface ReferralHistory extends ApiEntity {
    recommender: HTUser
    name: string
    mail: string
    reason: string
    phoneNo: string
    jobs: Job[]
}

const path = 'referrals'
export const getReferralHistory = (token: string) => https.get<ApiResponse<ReferralHistory>>([path, token].join("/"))