// import { useI18n } from 'vue-i18n';
import Cookies from 'js-cookie';

// Token
export const getToken = () => Cookies.get('token')
export const isAuth = () => Boolean(getToken())

// SSO
export const sso = () => {
    window.location.href = process.env.VUE_APP_SSO_URL + window.location
}

export const yearMonthToString = (date?: Date) => {
    if (!date) return ''
    date = new Date(date)
    const y = date.getFullYear()
    const m = (date.getMonth() + 1).toString().padStart(2, '0')
    return y + m
}
export const stringToYearMonth = (str?: string) => {
    if (!str || !/^\d{4}\d{2}$/.test(str)) return undefined
    return new Date(str.substring(0, 4) + '-' + str.substring(4) + '-01')
}

export const dateToString = (date?: Date) => {
    if (!date) return ''
    date = new Date(date)

    const y = date.getFullYear().toString().padStart(4, '0')
    const m = (date.getMonth() + 1).toString().padStart(2, '0')
    const d = date.getDate().toString().padStart(2, '0')
    return [y, m, d].join('-')
}
export const stringToDate = (str?: string) => {
    if (!str || !/^\d{4}-\d{2}-\d{2}$/.test(str)) return undefined
    return new Date(str)
}
