import { defineStore } from 'pinia'
import validate from '@/utils/validate'
import { CreateResumeDto, Seniority } from '@/api/resume.api'
import { GraduationStatus } from '@/api/school-experience.api'

const key = 'recruiting.resume'

export interface ResumeForm {
    step: number,
    invalidate: boolean
    referralMode: boolean
    agree: boolean
    locale: string

    lastName: string
    firstName: string
    engName: string
    mail: string
    phoneNo: string
    // status: ResumeStatus
    // graduateDate: string
    applicationChannel: number | undefined // ApplicationChannel
    applicationChannelOther: boolean
    applicationChannelDesc: string
    recommendToken?: string
    recommendEmpId?: string
    recommendEmpName?: string
    expectSalary?: string
    needWorkPermit?: boolean
    violatedIpRight?: boolean
    violatedIpRightDesc?: string
    seniority?: Seniority
    links: string[]
    jobs: number[]
    schoolExperiences: SchoolExperienceForm[]
    workExperiences: WorkExperienceForm[]
    recommenders: RecommendeForm[]

    attachments: File[]
    portfolio: File[]
}
export interface SchoolExperienceForm {
    school?: string
    department?: number
    education?: number
    status?: GraduationStatus
    toDateYear?: number
    toDateMonth?: number
}
export interface WorkExperienceForm {
    name?: string
    partTime?: boolean
    title?: string
    function?: string
    resignReason?: string
    salary?: string
    location?: string
    fromDateYear?: number
    fromDateMonth?: number
    toDateYear?: number
    toDateMonth?: number
    onTheJob?: boolean
}
export interface RecommendeForm {
    name?: string
    title?: string
    company?: string
    phoneNo?: string
}

export const useApplyForm = defineStore('applyForm', {
    state: (): ResumeForm => ({
        step: 1,
        invalidate: false,
        referralMode: false,
        agree: false,
        locale: 'TW',

        lastName: '',
        firstName: '',
        engName: '',
        mail: '',
        phoneNo: '',
        applicationChannel: undefined, // ApplicationChannel
        applicationChannelOther: false,
        applicationChannelDesc: '',
        recommendToken: undefined,
        recommendEmpId: undefined,
        recommendEmpName: undefined,
        expectSalary: undefined,
        needWorkPermit: false,
        violatedIpRight: undefined,
        violatedIpRightDesc: undefined,
        seniority: undefined,
        links: [''],
        jobs: [],
        schoolExperiences: [],
        workExperiences: [],
        recommenders: [],

        attachments: [],
        portfolio: []
    }),
    getters: {
        asDto(): CreateResumeDto {
            // const join = (year: number | null, month: number | null) => year && month ? [year, month.toString().padStart(2, '0')].join('/') : null
            const yyyyMMToTimestamp = (year?: number, month?: number) => {
                if (year && month) {
                    return new Date(year, month - 1).getTime()
                }
                return undefined
            }
            return {
                lastName: this.lastName,
                firstName: this.firstName,
                engName: this.engName,
                mail: this.mail,
                phoneNo: this.phoneNo,
                applicationChannel: this.applicationChannel ?? 0, // ApplicationChannel
                applicationChannelDesc: this.applicationChannelDesc,
                recommendToken: this.recommendToken,
                recommendEmpId: this.recommendEmpId,
                recommendEmpName: this.recommendEmpName,
                expectSalary: this.expectSalary,
                needWorkPermit: this.needWorkPermit ?? false,
                violatedIpRight: this.violatedIpRight,
                violatedIpRightDesc: this.violatedIpRightDesc,
                seniority: this.seniority,
                links: this.links,
                jobs: this.jobs,
                schoolExperiences: this.schoolExperiences.map(e => ({
                    school: e.school,
                    department: e.department,
                    education: e.education,
                    status: e.status,
                    toDate: yyyyMMToTimestamp(e.toDateYear, e.toDateMonth)
                })),
                workExperiences: this.workExperiences.map(e => ({
                    name: e.name,
                    partTime: e.partTime,
                    title: e.title,
                    function: e.function,
                    resignReason: e.resignReason,
                    salary: e.salary,
                    location: e.location,
                    fromDate: yyyyMMToTimestamp(e.fromDateYear, e.fromDateMonth),
                    toDate: yyyyMMToTimestamp(e.toDateYear, e.toDateMonth)
                })),
                recommenders: this.recommenders.map(e => ({
                    name: e.name,
                    title: e.title,
                    company: e.company,
                    phoneNo: e.phoneNo
                }))
            }
        }
    },
    actions: {
        nextStep() {
            this.step++
        },
        setStep() {
            localStorage.setItem([key, 'step'].join('.'), this.step.toString())
        },
        setLocale(locale: string) {
            localStorage.setItem([key, 'locale'].join('.'), locale?? 'TW')
        },
        setInvalidate(invalidate: boolean) {
            this.invalidate = invalidate
        },
        setReferralMode(enable: boolean) {
            this.referralMode = enable
            localStorage.setItem([key, 'referralMode'].join('.'), enable.toString())
        },
        setJobs(jobs: number[]) {
            this.jobs = jobs
            localStorage.setItem([key, 'jobs'].join('.'), JSON.stringify(jobs))
        },
        getLocale() {
            return localStorage.getItem([key, 'locale'].join('.'))?? 'TW'
        },
        getReferralMode() {
            const json = localStorage.getItem([key, 'referralMode'].join('.'))
            return json ? JSON.parse(json) as boolean : false
        },
        getJobs() {
            const json = localStorage.getItem([key, 'jobs'].join('.'))
            return json ? JSON.parse(json) as number[] : undefined
        },
        init() {
            let json = localStorage.getItem(key)
            if (json) this.$state = { ...this.$state, ...JSON.parse(json) }
            if (this.schoolExperiences.length === 0) this.schoolExperiences.push({})
            if (this.workExperiences.length === 0) this.workExperiences.push({})

            // step
            this.step = parseInt(localStorage.getItem([key, 'step'].join('.')) ?? '1')
            
            // locale
            this.locale = localStorage.getItem([key, 'locale'].join('.'))?? 'TW'

            // referral mode
            this.referralMode = localStorage.getItem([key, 'referralMode'].join('.')) === 'true'

            // jobs
            json = localStorage.getItem([key, 'jobs'].join('.'))
            this.jobs = json ? JSON.parse(json) : []
        },
        setLocalStorage(action: 'consent' | 'personal' | 'education' | 'experience' | 'application' | 'other') {
            const json = localStorage.getItem(key)
            const data = json ? JSON.parse(json) : {}
            switch (action) {
                case 'consent': {
                    const { agree } = this.$state
                    localStorage.setItem(key, JSON.stringify({ ...data, agree }))
                    break
                }
                case 'personal': {
                    const { lastName, firstName, engName, mail, phoneNo } = this.$state
                    localStorage.setItem(key, JSON.stringify({ ...data, lastName, firstName, engName, mail, phoneNo }))
                    break
                }
                case 'education': {
                    const { schoolExperiences } = this.$state
                    localStorage.setItem(key, JSON.stringify({ ...data, schoolExperiences }))
                    break
                }
                case 'experience': {
                    const { seniority, workExperiences } = this.$state
                    localStorage.setItem(key, JSON.stringify({ ...data, seniority, workExperiences }))
                    break
                }
                case 'application': {
                    const { applicationChannel, applicationChannelOther, applicationChannelDesc, recommendToken, recommendEmpId, recommendEmpName, expectSalary } = this.$state
                    localStorage.setItem(key, JSON.stringify({ ...data, applicationChannel, applicationChannelOther, applicationChannelDesc, recommendToken, recommendEmpId, recommendEmpName, expectSalary }))
                    break
                }
                case 'other': {
                    const { needWorkPermit, violatedIpRight, violatedIpRightDesc, recommenders, links } = this.$state
                    localStorage.setItem(key, JSON.stringify({ ...data, needWorkPermit, violatedIpRight, violatedIpRightDesc, recommenders, links }))
                    break
                }
            }
        },
        validateConsent() {
            let invalid = 0
            if (!this.agree) invalid++
            return invalid
        },
        validatePersional() {
            let invalid = 0
            if (!validate.required(this.lastName)) invalid++
            if (!validate.required(this.firstName)) invalid++
            if (!validate.required(this.engName)) invalid++
            if (!validate.required(this.mail) || !validate.email(this.mail)) invalid++
            if (!validate.required(this.phoneNo) || !validate.regex(this.phoneNo, /^\+?[0-9-]+$/)) invalid++
            return invalid
        },
        validateEducation() {
            // let invalid = 0
            // return invalid
            return 0
        },
        validateApplication() {
            let invalid = 0
            if (!validate.required(this.applicationChannel)) invalid++
            if (this.applicationChannelOther && !validate.required(this.applicationChannelDesc)) invalid++
            return invalid
        },
        validateOther() {
            let invalid = 0
            if (!validate.required(this.needWorkPermit)) invalid++
            if (!validate.required(this.attachments)) invalid++
            return invalid
        },
        validate() {
            // consent
            if (this.validateConsent() > 0) {
                this.step = 1
                this.invalidate = true
                return false
            }
            // personal
            if (this.validatePersional() > 0) {
                this.step = 2
                this.invalidate = true
                return false
            }
            // education
            if (this.validateEducation() > 0) {
                this.step = 3
                this.invalidate = true
                return false
            }
            // experience
            // nothing
            // application
            if (this.validateApplication() > 0) {
                this.step = 5
                this.invalidate = true
                return false
            }
            // other
            if (this.validateOther() > 0) {
                this.step = 6
                this.invalidate = true
                return false
            }
            return true
        },
        clear() {
            localStorage.removeItem(key)
            localStorage.removeItem([key, 'step'].join('.'))
            localStorage.removeItem([key, 'locale'].join('.'))
            localStorage.removeItem([key, 'jobs'].join('.'))
            localStorage.removeItem([key, 'referralMode'].join('.'))
            this.step = 1
            this.locale = 'TW'
            this.invalidate = false
            this.referralMode = false
            this.agree = false

            this.lastName = ''
            this.firstName = ''
            this.engName = ''
            this.mail = ''
            this.phoneNo = ''

            this.schoolExperiences = []
            this.schoolExperiences.push({})

            this.seniority = undefined
            this.workExperiences = []
            this.workExperiences.push({})

            this.recommenders = []
            this.recommenders.push({})

            this.applicationChannel = undefined
            this.expectSalary = undefined
            this.recommendToken = undefined
            this.recommendEmpId = undefined
            this.recommendEmpName = undefined

            this.needWorkPermit = undefined
            this.violatedIpRight = undefined
            this.violatedIpRightDesc = undefined
            this.attachments = []
            this.portfolio = []
            this.links = ['']
        }
    }
})
