export default {
  "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "keyWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "nav": {
    "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Vacancy Query"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification and Consent Letter"])}
  },
  "job": {
    "applying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are applying for"])},
    "recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your friend recommended this job"])},
    "requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirement"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Description"])},
    "query": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Vacancy Query"])},
      "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Please click the job and apply"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Type"])},
      "employmentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment Type"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Location"])},
      "fullTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Time"])},
      "partTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part Time"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
    },
    "list": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job List"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job code"])},
      "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Vacancy"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Location"])},
      "noWorkExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No work experience is required"])},
      "noLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])}
    },
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])}
  },
  "apply": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Job page"])},
    "consent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification and Consent Letter under the Personal Information Protection Act (Recruit)"])},
      "exTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification and Consent Letter for the Utilization of Personal Information beyond the Specific Purpose"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have READ and AGREED with the terms and conditions"])},
      "invalidate": {
        "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check 'I have READ and AGREED with the terms and conditions'"])}
      }
    },
    "personal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
      "chineseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "englishName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Name"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "invalidate": {
        "chineseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Chinese Name"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Last Name"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your First Name"])},
        "englishName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your English Name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your E-mail"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Phone Number"])},
        "emailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid E-mail address"])},
        "phoneFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid Phone Number"])}
      }
    },
    "education": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "qualifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualifications"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
      "deptClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department Classfication"])},
      "graduationDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation Date"])},
      "dropOutDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop Out Date"])},
      "schoolinDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected Graduation Date"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation"])},
      "graduated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduated"])},
      "dropout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop out"])},
      "schooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In school"])},
      "warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in highest and second highest level of education."])},
      "invalidate": {
        "graduationDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter 'yyyy/MM' format"])}
      }
    },
    "experience": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
      "seniority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of relevant working experience"])},
      "noExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Experience"])},
      "belowOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below One Year"])},
      "aboveOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above One Year"])},
      "aboveTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Two Year"])},
      "aboveThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Three Year"])},
      "aboveFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Four Year"])},
      "aboveFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Five Year"])},
      "aboveSix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Six Year"])},
      "aboveSeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Seven Year"])},
      "aboveEight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Eight Year"])},
      "aboveNine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Nine Year"])},
      "aboveTen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Ten Year"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please start with your most recent work experience"])},
      "onTheJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-the-job"])},
      "experienceCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience Category"])},
      "workExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
      "partTimeExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part-time Experience"])},
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Location"])},
      "servingZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serving Zone"])},
      "startYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start (Year)"])},
      "startMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start (Month)"])},
      "endYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End (Year)"])},
      "endMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End (Month)"])},
      "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
      "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
      "jobContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Content"])},
      "reasonForLeaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for leaving"])},
      "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
      "supervisorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "supervisorJobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
      "supervisorPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "invalidate": {
        "servingZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter 'yyyy/MM' format"])}
      }
    },
    "application": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Related"])},
      "applicationChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Channel"])},
      "desiredJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desired Job"])},
      "expectSalary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected Salary"])},
      "recommendToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommend Code"])},
      "recommenderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommender Employee ID"])},
      "recommenderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommender Name"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "invalidate": {
        "applicationChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select 'Application Channel'"])},
        "applicationChannelDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter 'Application Channel'"])}
      }
    },
    "other": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "isRequiredWorkPermit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Had required to apply for work permits?"])},
      "isRequiredWorkPermitDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please provide national identity cards or related documents.)"])},
      "hadViolatedIpRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you ever been convicted of any criminal offense?"])},
      "hadViolatedIpRightDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give details"])},
      "recommender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommnender"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please list three individuals who can provide references for your character and abilities (excluding relatives and friends)."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
        "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company"])},
        "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone NO./Email"])}
      },
      "uploadHeadshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your headshot with white background"])},
      "uploadAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload CV/Resume"])},
      "uploadHeadshotDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only png and jpg are acceptable, max 10MB"])},
      "uploadAttachmentDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only pdf and word are acceptable, max 10MB"])},
      "uploadPortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload file"])},
      "uploadPortfolioDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio, transcript..., max 10MB"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio link or other reference link"])},
      "invalidate": {
        "isRequiredWorkPermit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select 'Had required to apply for work permits?'"])},
        "uploadHeadshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload headshot"])},
        "uploadAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload CV/Resume"])}
      }
    }
  },
  "delete-resume": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Cancel Resume"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "sendSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent successfully, please check your email"])},
    "invalidate": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your E-mail"])},
      "emailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid E-mail address"])}
    },
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete all resume ?"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel resume success"])}
  },
  "personal-info": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "consent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification and Consent Letter under the Personal Information Protection Act (Recruit)"])},
      "exTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification and Consent Letter for the Utilization of Personal Information beyond the Specific Purpose"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have READ and AGREED with the terms and conditions"])},
      "invalidate": {
        "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check 'I have READ and AGREED with the terms and conditions'"])}
      }
    },
    "personal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
      "chineseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "englishName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Name"])},
      "gender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])}
      },
      "idNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID or Passport"])},
      "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Date"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "phoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone NO"])},
      "homePhoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Phone NO"])},
      "contactPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Address ZIP"])},
      "registerPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Address ZIP"])},
      "contactAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Address"])},
      "registerAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Address"])},
      "militaryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Military Status"])},
      "militaryFromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Military From Date"])},
      "militaryToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Military To Date"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "invalidate": {
        "chineseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Chinese Name"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Last Name"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "englishName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your English Name"])},
        "gender": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Gender"])}
        },
        "idNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your ID or Passport"])},
        "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Birth Date"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Country"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your E-mail"])},
        "phoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Phone Number"])},
        "homePhoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Home Phone NO"])},
        "contactPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Contact Address ZIP"])},
        "registerPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Register Address ZIP"])},
        "contactAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Contact Address"])},
        "registerAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Register Address"])},
        "militaryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Military Status"])},
        "militaryFromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Military From Date"])},
        "militaryToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Military To Date"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Phone Number"])},
        "phoneNoFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid Contact Number"])},
        "homePhoneNoFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid Residential Number"])},
        "emailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid E-mail address"])},
        "phoneFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid Phone Number"])},
        "contactPostalCodeFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid ZIP Code"])},
        "registerPostalCodeFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid ZIP Code"])}
      }
    },
    "education": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration of Study"])},
      "fromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "toDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
      "qualifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualifications"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
      "deptClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department Classfication"])},
      "graduationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation Day"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation"])},
      "graduated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduated"])},
      "drop out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop out"])},
      "schooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schooling"])},
      "graduationDateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYYMM"])},
      "invalidate": {
        "qualifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Qualifications"])},
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter School"])},
        "deptClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Department Classfication"])},
        "fromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Year(YYYY) and Month(MM) YYYYMM format"])},
        "fromDateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Year(YYYY) and Month(MM) YYYYMM format"])},
        "toDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Year(YYYY) and Month(MM) YYYYMM format"])},
        "toDateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Year(YYYY) and Month(MM) YYYYMM format"])},
        "graduationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Year(YYYY) and Month(MM) YYYYMM format"])},
        "graduationDateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Year(YYYY) and Month(MM) YYYYMM format"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Graduation"])}
      }
    },
    "experience": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
      "seniority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of relevant working experience"])},
      "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in starting from the latest one, up to five entries"])},
      "noExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Experience"])},
      "belowOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below One Year"])},
      "aboveOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above One Year"])},
      "aboveTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Two Year"])},
      "aboveThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Three Year"])},
      "aboveFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Four Year"])},
      "aboveFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Five Year"])},
      "aboveSix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Six Year"])},
      "aboveSeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Seven Year"])},
      "aboveEight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Eight Year"])},
      "aboveNine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Nine Year"])},
      "aboveTen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above Ten Year"])},
      "experienceCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience Category"])},
      "workExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
      "partTimeExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part-time Experience"])},
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Location"])},
      "servingZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serving Zone"])},
      "startYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start (Year)"])},
      "startMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start (Month)"])},
      "endYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End (Year)"])},
      "endMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End (Month)"])},
      "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
      "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
      "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
      "supervisorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "supervisorJobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
      "supervisorPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "invalidate": {
        "seniority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Seniority"])}
      }
    },
    "application": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Related"])},
      "applicationChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Channel"])},
      "desiredJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desired Job"])},
      "expectSalary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected Salary"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "invalidate": {
        "applicationChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select 'Application Channel'"])}
      }
    },
    "other": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "isIllnessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health condition: Do you have a illness handbook, any chronic diseases, infectious diseases, or have undergone surgery?"])},
      "healthStatusDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give details"])},
      "emergency": {
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Person-Name"])},
        "relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Person-Relationship"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Person-Addres"])},
        "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Person-Phone NO"])}
      },
      "recommender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommnender"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please list three individuals who can provide references for your character and abilities (excluding relatives and friends)."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
        "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company"])},
        "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone NO"])}
      },
      "isRequiredWorkPermit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Had required to apply for work permits?"])},
      "isRequiredWorkPermitDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please provide national identity cards or related documents.)"])},
      "hadViolatedIpRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you ever been convicted of any criminal offense?"])},
      "hadViolatedIpRightDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give details"])},
      "uploadAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload CV/Resume"])},
      "uploadAttachmentDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only pdf and word are acceptable, max 10MB"])},
      "uploadedHeadshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded headhost"])},
      "uploadHeadshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your headshot with white background"])},
      "uploadHeadshotDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only png and jpg are acceptable, max 10MB"])},
      "invalidate": {
        "isRequiredWorkPermit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select 'Had required to apply for work permits?'"])},
        "isIllnessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your Health condition"])},
        "emergency": {
          "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Emergency Person-Name"])},
          "relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Emergency Person-Relationship"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Emergency Person-Addres"])},
          "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Emergency Person-Phone NO"])}
        },
        "uploadAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload CV/Resume"])},
        "uploadHeadshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload headshot"])}
      }
    }
  },
  "resume": {
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio link or other reference link"])},
    "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV/Resume File"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])}
  },
  "policy": {
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "cookie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Policy"])}
  },
  "referral": {
    "recommender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommender"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "phoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone No"])}
  }
}