<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-select v-model="vm.seniority" :items="seniorities" :label="$t('personal-info.experience.seniority')"
                    item-value="value" item-title="title" class="required"
                    :rules="[v => validate.required(v, 'personal-info.experience.invalidate.seniority')]" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn color="#606060" variant="text">{{ $t('personal-info.experience.tip') }}</v-btn>
                <v-expansion-panels v-model="panels" variant="accordion" multiple>
                    <v-expansion-panel v-for="(item, index) in vm.workExperiences" :key="index">
                        <v-expansion-panel-title @click="onClickPanel(index)">
                            <span>{{ [$t('personal-info.experience.title'), index + 1].join(' ') }}</span>
                            <v-spacer></v-spacer>
                            <v-icon v-if="vm.workExperiences.length > 1" @click.stop="remove(index)" color="#616264"
                                class="mr-2">mdi-close-circle</v-icon>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-container>
                                <v-row>
                                    <v-col md="6" sm="12">
                                        <v-radio-group v-model="item.partTime"
                                            :label="$t('personal-info.experience.experienceCategory')" inline>
                                            <v-radio :label="$t('personal-info.experience.workExperience')"
                                                :value="false"></v-radio>
                                            <v-radio :label="$t('personal-info.experience.partTimeExperience')"
                                                :value="true"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="6" sm="12">
                                        <htc-text-field v-model="item.name"
                                            :label="$t('personal-info.experience.companyName')" clearable></htc-text-field>
                                    </v-col>
                                    <v-col md="6" sm="12">
                                        <htc-text-field v-model="item.location"
                                            :label="$t('personal-info.experience.location')" clearable></htc-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-label>{{ $t('personal-info.experience.servingZone') }}</v-label>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col md="6" sm="6">
                                                <htc-text-field :model-value="dateToString(item.fromDate)"
                                                    @update:model-value="item.fromDate = stringToDate($event)"
                                                    :label="$t('personal-info.experience.startYear') + $t('personal-info.experience.startMonth')"
                                                    type="date" clearable></htc-text-field>
                                            </v-col>
                                            <v-col md="6" sm="6">
                                                <htc-text-field :model-value="dateToString(item.toDate)"
                                                    @update:model-value="item.toDate = stringToDate($event)"
                                                    :label="$t('personal-info.experience.endYear') + $t('personal-info.experience.endMonth')"
                                                    type="date" clearable></htc-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="6" sm="12">
                                        <htc-text-field v-model="item.title"
                                            :label="$t('personal-info.experience.jobTitle')" clearable></htc-text-field>
                                    </v-col>
                                    <v-col md="6" sm="12">
                                        <htc-text-field v-model="item.salary" :label="$t('personal-info.experience.salary')"
                                            clearable></htc-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn class="htc-btn" @click="add">{{ $t('create') }}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
import HtcTextField from '@/components/HtcTextField.vue'
import validate from '@/utils/validate'

import { useI18n } from 'vue-i18n';
import { SapWorkExperience } from '@/api/sap-work-experience.api';
import { SapPersonalInfoVm } from '@/api/sap-personal-info.api'
import { reactive, computed } from 'vue'
import { stringToDate, dateToString } from '@/utils'
import { Seniority } from '@/api/resume.api'

const props = defineProps<{
    modelValue: SapPersonalInfoVm
}>()

const panels = reactive(props.modelValue.workExperiences.map((e, i) => i))
const vm = computed(() => {
    return props.modelValue
})

const i18n = useI18n()
const seniorities = computed(() => [
    { title: i18n.t('personal-info.experience.noExperience'), value: Seniority.NO_EXPERIENCE },
    { title: i18n.t('personal-info.experience.belowOne')    , value: Seniority.BELOW_ONE },
    { title: i18n.t('personal-info.experience.aboveOne')    , value: Seniority.ABOVE_ONE },
    { title: i18n.t('personal-info.experience.aboveTwo')    , value: Seniority.ABOVE_TWO },
    { title: i18n.t('personal-info.experience.aboveThree')  , value: Seniority.ABOVE_THREE },
    { title: i18n.t('personal-info.experience.aboveFour')   , value: Seniority.ABOVE_FOUR },
    { title: i18n.t('personal-info.experience.aboveFive')   , value: Seniority.ABOVE_FIVE },
    { title: i18n.t('personal-info.experience.aboveSix')    , value: Seniority.ABOVE_SIX },
    { title: i18n.t('personal-info.experience.aboveSeven')  , value: Seniority.ABOVE_SEVEN },
    { title: i18n.t('personal-info.experience.aboveEight')  , value: Seniority.ABOVE_EIGHT },
    { title: i18n.t('personal-info.experience.aboveNine')   , value: Seniority.ABOVE_NINE },
    { title: i18n.t('personal-info.experience.aboveTen')    , value: Seniority.ABOVE_TEN }
])

const onClickPanel = (index: number) => {
    if (panels.indexOf(index) > -1) {
        panels.splice(panels.indexOf(index), 1)
    } else {
        panels.push(index)
    }
}
const remove = (index: number) => {
    if (vm.value.workExperiences.length > 1) {
        vm.value.workExperiences.splice(index, 1)
        if (panels.indexOf(index) > -1) panels.splice(panels.indexOf(index), 1)
    }
}
const add = () => {
    if (vm.value.workExperiences.length < 5) {
        vm.value.workExperiences.push({} as SapWorkExperience)
        panels.push(vm.value.workExperiences.length - 1)
    }
}
</script>

<style scoped>
.htc-btn * {
    color: white;
    background-color: #A5CF4C;
    font-weight: bold;
}

.hover {
    color: #A5CF4C;
    background-color: white;
    border-color: #A5CF4C;
    border: 1px solid;
}
</style>