<template>
    <v-container>
        <v-layout style="min-height: 77vh">
            <template v-if="step <= max">
                <template v-if="mobile">
                    <v-app-bar :elevation="0" color="#A5CF4C">
                        <template v-slot:prepend>
                            <v-btn @click="nav = !nav" icon>
                                <v-badge :model-value="views.filter(e => e.valid).length > 0" dot color="#E53935">
                                    <v-icon color="#FFF">mdi-menu</v-icon>
                                </v-badge>
                            </v-btn>
                        </template>
                        <v-app-bar-title style="color: #fff">{{ $t(views.find(e => step === e.step)?.title ?? '')
                        }}</v-app-bar-title>
                    </v-app-bar>
                    <v-navigation-drawer v-model="nav" :border="0" style="background-color: #A5CF4C;">
                        <v-list @click:select="onClick" @update:selected="onUpdate" :disabled="step > max"
                            density="comfortable" active-class="apply-nav-active" variant="plain" class="px-0 pt-0" nav
                            absolute mandatory>
                            <v-list-item v-for="e in views" :key="e.step" :value="e.step" :active="step === e.step"
                                :title="$t(e.title)" rounded="0" class="apply-nav-default">
                                <template #title="{ title }">
                                    <span style="white-space: pre-wrap;">{{ title }}</span>
                                </template>
                                <template v-if="step !== e.step && e.valid" #append>
                                    <v-badge :content="e.valid" color="#E53935" inline></v-badge>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-navigation-drawer>
                </template>
                <template v-else>
                    <v-navigation-drawer :border="0" style="background-color: #A5CF4C" permanent>
                        <v-list @click:select="onClick" @update:selected="onUpdate" :disabled="step > max"
                            density="comfortable" active-class="apply-nav-active" variant="plain" nav absolute mandatory>
                            <v-list-item v-for="e in views" :key="e.step" :value="e.step" :active="step === e.step"
                                :title="$t(e.title)" rounded class="apply-nav-default">
                                <template #title="{ title }">
                                    <span style="white-space: pre-wrap;">{{ title }}</span>
                                </template>
                                <template v-if="step !== e.step && e.valid" #append>
                                    <v-badge :content="e.valid" color="#E53935" inline></v-badge>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-navigation-drawer>
                </template>
            </template>
            <v-main>
                <v-form ref="form">
                    <v-window v-model="step" direction="vertical" disabled>
                        <v-window-item v-for="e in views" :key="e.step" :value="e.step">
                            <component :is="e.view"></component>
                        </v-window-item>
                        <v-window-item :value="max + 1">
                            <v-card-text>
                                <div class="pa-4 text-center">
                                    <v-img class="mb-4" contain height="128" src="@/assets/images/htc-logo.svg"></v-img>
                                    <h3 class="text-h6 font-weight-light mb-2">Thanks for your application !</h3>
                                    <v-btn to="job" @click="store.clear" class="mt-3 htc-btn">{{ $t('apply.home') }}</v-btn>
                                </div>
                            </v-card-text>
                        </v-window-item>
                    </v-window>
                </v-form>
                <v-container fluid>
                    <v-divider></v-divider>
                    <div class="d-flex w-100 mt-2">
                        <v-btn v-if="min < step && step <= max" @click="step--" variant="text" class="htc-btn">{{
                            $t('apply.back') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn v-if="step < max" @click="step++" variant="flat" class="htc-btn">{{ $t('apply.next')
                        }}</v-btn>
                        <v-btn v-if="step === max" @click="onSubmitClick" :disabled="submitDisabled" variant="flat"
                            class="htc-btn">
                            <v-progress-circular v-if="submitDisabled" size="20" indeterminate></v-progress-circular>
                            <span v-else>{{ $t('apply.submit') }}</span>
                        </v-btn>
                    </div>
                </v-container>
            </v-main>
        </v-layout>
    </v-container>
</template>

<script setup lang="ts">
import ApplicationView from './apply/ApplicationView.vue'
import ExperienceView from './apply/ExperienceView.vue'
import EducationView from './apply/EducationView.vue'
import PersonalView from './apply/PersonalView.vue'
import ConsentView from './apply/ConsentView.vue'
import OtherView from './apply/OtherView.vue'
import router from '@/router'

import { onBeforeMount, computed, markRaw, nextTick, ref, watch } from 'vue'
import { useApplyForm } from '@/store/applyForm'
import { createResume } from '@/api/resume.api'
import { useLoading } from '@/store/loading'
import { toast } from 'vue3-toastify'
import { useDisplay } from 'vuetify'
import { storeToRefs } from 'pinia'

const { mobile } = useDisplay()
const store = useApplyForm()

const { step, invalidate } = storeToRefs(store)
onBeforeMount(() => {
    store.init()
})

const views = computed(() => [
    { step: 1, view: markRaw(ConsentView), title: 'apply.consent.title', valid: store.validateConsent() },
    { step: 2, view: markRaw(PersonalView), title: 'apply.personal.title', valid: store.validatePersional() },
    { step: 3, view: markRaw(EducationView), title: 'apply.education.title', valid: store.validateEducation() },
    { step: 4, view: markRaw(ExperienceView), title: 'apply.experience.title' },
    { step: 5, view: markRaw(ApplicationView), title: 'apply.application.title', valid: store.validateApplication() },
    { step: 6, view: markRaw(OtherView), title: 'apply.other.title', valid: store.validateOther() },
])
const min = ref(Math.min(...views.value.map(e => e.step)))
const max = ref(Math.max(...views.value.map(e => e.step)))
const nav = ref(false)
const form = ref<HTMLFormElement>() // reference <v-form ref="form">
const loading = useLoading()
const submitDisabled = ref(false)
const windowWidth = ref(window.innerWidth)

watch([step, invalidate], async () => {
    store.setStep()
    await nextTick()
    if (invalidate.value) {
        form.value?.validate()
        store.setInvalidate(false)
    }
})
watch(windowWidth, () => {
    if (mobile.value) nav.value = false
})

const onSubmitClick = async () => {
    if (!store.validate()) return
    loading.show()
    submitDisabled.value = true

    const json = localStorage.getItem('recruiting.resume.jobs')
    const data = json ? JSON.parse(json) as number[] : undefined
    if (!data) {
        submitDisabled.value = false
        loading.hide()
        toast.error('Job not found')

        setTimeout(() => {
            router.replace({ name: 'job' })
        }, 3000)
        return
    }
    store.jobs.push(...data)
    const { data: res } = await createResume(store.asDto, store.attachments, store.portfolio)
    if (res.success) {
        await nextTick()
        store.nextStep()
    } else {
        toast.error(res.message)
    }
    submitDisabled.value = false
    loading.hide()
}
const onClick = (val: { id: unknown, value: boolean, path: unknown[] }) => {
    step.value = val.id as number
    if (mobile.value) nav.value = false
}
const onUpdate = (selected: unknown[]) => {
    step.value = selected[0] as number
}
</script>

<style scoped>
/*
.v-btn {
    color: #FFFFFF;
    background-color: #A5CF4C;
}
*/
.apply-nav-default {
    color: #FFFFFF;
    background-color: #A5CF4C;
    opacity: 1.0
}

.apply-nav-active {
    color: #A5CF4C;
    background-color: #FFFFFF;
}
</style>
