import { ApiResponse, ApiEntity, https } from '.'

export interface Education extends ApiEntity {
    code: string
    name: string
    engName: string
    sequence: number
}

export const getEducations = () => https.get<ApiResponse<Education[]>>('educations')
