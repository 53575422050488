import { ApiResponse, ApiEntity, https } from '.'

export interface JobLocation extends ApiEntity {
    code: string
    name: string
    engName: string
    address: string
    engAddress: string
}

export const getJobLocations = () => https.get<ApiResponse<JobLocation[]>>('jobLocations')
