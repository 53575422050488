<template>
    <v-container>
        <v-row>
            <v-col>
                <h3 class="text-h6">{{ userName }} {{ $t('delete-resume.hello') }}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-list lines="two" select-strategy="classic">
                    <template v-for="(e, idx) in details" :key="idx">
                        <v-list-item @click="onClickDetail(e.id)" :ripple="false" elevation="1">
                            <v-list-item-title>
                                <p v-for="(job, i) in e.jobs" :key="i">{{ job }}</p>
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ e.createTimestamp }}</v-list-item-subtitle>
                        </v-list-item>
                    </template>
                </v-list>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn @click="confirmDialog = true" :disabled="deleteDisabled" class="delete-btn">
                    <v-progress-circular v-if="deleteDisabled" size="20" indeterminate></v-progress-circular>
                    <span v-else>{{ $t('delete') }}</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="detailDialog" width="auto" min-width="80%">
            <v-card>
                <v-card-title class="d-flex justify-end">
                    <v-btn @click="detailDialog = false" icon="mdi-close" variant="text"></v-btn>
                </v-card-title>
                <v-card-text class="overflow-y-auto">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-label>{{ $t('apply.personal.chineseName') }}</v-label>
                            </v-col>
                            <v-col cols="6" md="3">{{ resume?.name }}</v-col>
                            <v-col cols="6" md="3">
                                <v-label>{{ $t('apply.personal.englishName') }}</v-label>
                            </v-col>
                            <v-col cols="6" md="3">{{ resume?.engName }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-label>{{ $t('email') }}</v-label>
                            </v-col>
                            <v-col cols="6" md="3">{{ resume?.mail }}</v-col>
                            <v-col cols="6" md="3">
                                <v-label>{{ $t('apply.personal.phone') }}</v-label>
                            </v-col>
                            <v-col cols="6" md="3">{{ resume?.phoneNo }}</v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <template v-for="schoolExperience in resume?.schoolExperiences" :key="schoolExperience.id">
                            <v-row>
                                <v-col cols="6" md="3">
                                    <v-label>{{ $t('apply.education.qualifications') }}</v-label>
                                </v-col>
                                <v-col cols="6" md="3">
                                    {{ 'us' === $i18n.locale ? schoolExperience.education?.engName : schoolExperience.education?.name }}
                                </v-col>
                                <v-col cols="6" md="3">
                                    <v-label>{{ $t('apply.education.school') }}</v-label>
                                </v-col>
                                <v-col cols="6" md="3">{{ schoolExperience.school }}</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="3">
                                    <v-label>{{ $t('apply.education.deptClassification') }}</v-label>
                                </v-col>
                                <v-col cols="6" md="9">
                                    {{ 'us' === $i18n.locale ? schoolExperience.department?.engName : schoolExperience.department?.name }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="3">
                                    <v-label>{{ $t('apply.education.graduationDay') }}</v-label>
                                </v-col>
                                <v-col cols="6" md="3">{{ schoolExperience.toDate ? $d(schoolExperience.toDate, 'yyyyMM') : '' }}</v-col>
                                <v-col cols="6" md="3">
                                    <v-label>{{ $t('apply.education.status') }}</v-label>
                                </v-col>
                                <v-col cols="6" md="3">{{ educationStatusDesc(schoolExperience.status) }}</v-col>
                            </v-row>
                            <v-divider class="my-4"></v-divider>
                        </template>
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-label>{{ $t('apply.experience.seniority') }}</v-label>
                            </v-col>
                            <v-col cols="6" md="3">{{ seniorityDesc(resume?.seniority ?? '') }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card v-for="(workExperience, i) in resume?.workExperiences" :key="i" elevation="0"
                                    :border="true">
                                    <v-card-title>
                                        <v-label>{{ [$t('apply.experience.title'), i + 1].join(' ') }}</v-label>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="6" md="3">
                                                    <v-label>{{ $t('apply.experience.experienceCategory') }}</v-label>
                                                </v-col>
                                                <v-col cols="6" md="3">
                                                    {{ workExperience.partTime ? $t('apply.experience.workExperience') :
                                                        $t('apply.experience.partTimeExperience') }}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6" md="3">
                                                    <v-label>{{ $t('apply.experience.companyName') }}</v-label>
                                                </v-col>
                                                <v-col cols="6" md="3">{{ workExperience.name }}</v-col>
                                                <v-col cols="6" md="3">
                                                    <v-label>{{ $t('apply.experience.location') }}</v-label>
                                                </v-col>
                                                <v-col cols="6" md="3">{{ workExperience.location }}</v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6" md="3">
                                                    <v-label>{{ $t('apply.experience.servingZone') }}</v-label>
                                                </v-col>
                                                <v-col cols="6" md="9">
                                                    {{ workExperience.fromDate ? $d(workExperience.fromDate, 'yyyyMM') : ''
                                                    }}
                                                    {{ workExperience.fromDate || workExperience.toDate ? '~' : '' }}
                                                    {{ workExperience.toDate ? $d(workExperience.toDate, 'yyyyMM') : '' }}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6" md="3">
                                                    <v-label>{{ $t('apply.experience.jobTitle') }}</v-label>
                                                </v-col>
                                                <v-col cols="6" md="3">{{ workExperience.title }}</v-col>
                                                <v-col cols="6" md="3">
                                                    <v-label>{{ $t('apply.experience.salary') }}</v-label>
                                                </v-col>
                                                <v-col cols="6" md="3">{{ workExperience.salary }}</v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3">
                                                    <v-label>{{ $t('apply.experience.jobContent') }}</v-label>
                                                </v-col>
                                                <v-col cols="12" md="9" class="word-wrap">{{ workExperience.function
                                                }}</v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3">
                                                    <v-label>{{ $t('apply.experience.reasonForLeaving') }}</v-label>
                                                </v-col>
                                                <v-col cols="12" md="9" class="word-wrap">{{ workExperience.resignReason
                                                }}</v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-label>{{ $t('apply.application.applicationChannel') }}</v-label>
                            </v-col>
                            <v-col cols="6" md="9">
                                {{ 'us' === $i18n.locale ? resume?.applicationChannel.engName : resume?.applicationChannel.name }}
                                {{ resume?.applicationChannelDesc && (': ' + resume?.applicationChannelDesc ) }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-label>{{ $t('apply.application.recommenderId') }}</v-label>
                            </v-col>
                            <v-col cols="6" md="3">{{ resume?.recommendEmpId }}</v-col>
                            <v-col cols="6" md="3">
                                <v-label>{{ $t('apply.application.recommenderName') }}</v-label>
                            </v-col>
                            <v-col cols="6" md="3">{{ resume?.recommendEmpName }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card class="mb-4" elevation="0" :border="true">
                                    <v-card-title>
                                        <v-label :text="$t('apply.application.desiredJob')"></v-label>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-list v-if="resume" lines="two" select-strategy="classic">
                                            <template v-for="job in resume.jobs" :key="job.id">
                                                <v-list-item :ripple="false" elevation="1">
                                                    <v-list-item-title>{{ job.name }}</v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        <htc-crumbs
                                                            :items="[educationDisplay(job.educations), job.major, job.seniority]"></htc-crumbs>
                                                    </v-list-item-subtitle>
                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-label>{{ $t('apply.application.expectSalary') }}</v-label>
                            </v-col>
                            <v-col cols="6" md="9">{{ resume?.expectSalary }}</v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-label>{{ $t('apply.other.isRequiredWorkPermit') }}</v-label>
                            </v-col>
                            <v-col cols="6" md="9">
                                <v-icon v-if="resume?.needWorkPermit" color="#A5CF4C">mdi-check-circle-outline</v-icon>
                                <v-icon v-else color="#E53935">mdi-close-circle-outline</v-icon>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-label>{{ $t('apply.other.hadViolatedIpRight') }}</v-label>
                            </v-col>
                            <v-col cols="6" md="9">
                                <v-icon v-if="resume?.violatedIpRight" color="#A5CF4C">mdi-check-circle-outline</v-icon>
                                <v-icon v-else color="#E53935">mdi-close-circle-outline</v-icon>
                            </v-col>
                        </v-row>
                        <v-row v-if="resume?.violatedIpRight">
                            <v-col>
                                <v-card elevation="0" :border="true">
                                    <v-card-text class="word-wrap">{{ resume?.violatedIpRightDesc }}</v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-label>{{ $t('apply.other.link') }}</v-label>
                            </v-col>
                            <v-col cols="12" md="9">
                                <a v-for="(link, i) in resume?.links" :key="i" :href="link" target="_blank">
                                    <p>{{ link }}</p>
                                </a>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <htc-confirm-dialog v-model="confirmDialog" @confirm="onDeleteConfirm" :title="$t('confirm')" :text="$t('delete-resume.confirm')"></htc-confirm-dialog>
    </v-container>
</template>
<script setup lang="ts">
import HtcCrumbs from '@/components/HtcCrumbs.vue'
import HtcConfirmDialog from '@/components/HtcConfirmDialog.vue'

import { computed, onBeforeMount, reactive, ref, watch } from 'vue'
import { DeleteResume, deleteResume, get } from '@/api/delete-resume.api'
import { getResume, Resume, Seniority } from '@/api/resume.api'
import { GraduationStatus } from '@/api/school-experience.api'
import { Education } from '@/api/education.api'
import { useRoute, useRouter } from 'vue-router'
import { useLoading } from '@/store/loading'
import { toast } from 'vue3-toastify'
import { useI18n } from 'vue-i18n'

const loading = useLoading()
const router = useRouter()
const route = useRoute()
const i18n = useI18n()

const deleteDisabled = ref(false)
const detailDialog = ref(false)
const details = reactive<DeleteResume[]>([])
const resume = ref<Resume>()
const confirmDialog = ref(false)

const userName = computed(() => {
    if (details.length > 0) {
        return 'tw' === i18n.locale.value ? details[0].chineseName : details[0].englishName
    }
    return ''
})

onBeforeMount(async () => {
    loading.show()
    const { data: res } = await get(route.params.token as string)
    if (res.success) {
        if (!res.result || res.result.length === 0) {
            toast.error('Data is empty')
        } else {
            res.result.forEach(e => details.push(e));
        }
    } else {
        toast.error(res.message)
        router.replace({ name: 'job' })
    }
    loading.hide()
})

watch(detailDialog, (value) => {
    if (!value) {
        resume.value = undefined
    }
})

const onClickDetail = async (id: number) => {
    detailDialog.value = true
    const { data: res } = await getResume(id)
    resume.value = res.result
}

const onDeleteConfirm = async () => {
    loading.show()
    confirmDialog.value = false
    deleteDisabled.value = true
    const { data: res } = await deleteResume(route.params.token as string)
    if (res.success) {
        toast.success(i18n.t('delete-resume.success'))
        setTimeout(() => {
            router.replace({ name: 'job' })
            deleteDisabled.value = false
        }, 1500)
    } else {
        toast.error(res.message)
        deleteDisabled.value = false
    }
    loading.hide()
}

const educationStatusDesc = (status: GraduationStatus) => {
    switch (status) {
        case GraduationStatus.GRADUATED: return i18n.t('apply.education.graduated')
        case GraduationStatus.DROP_OUT: return i18n.t('apply.education.dropout')
        case GraduationStatus.SCHOOLING: return i18n.t('apply.education.schooling')
        default: return ''
    }
}

const seniorityDesc = (seniority: string) => {
    switch (Seniority[seniority as keyof typeof Seniority]) {
        case Seniority.NO_EXPERIENCE: return i18n.t('apply.experience.noExperience')
        case Seniority.BELOW_ONE: return i18n.t('apply.experience.belowOne')
        case Seniority.ABOVE_ONE: return i18n.t('apply.experience.aboveOne')
        case Seniority.ABOVE_TWO: return i18n.t('apply.experience.aboveTwo')
        case Seniority.ABOVE_THREE: return i18n.t('apply.experience.aboveThree')
        case Seniority.ABOVE_FOUR: return i18n.t('apply.experience.aboveFour')
        case Seniority.ABOVE_FIVE: return i18n.t('apply.experience.aboveFive')
        case Seniority.ABOVE_SIX: return i18n.t('apply.experience.aboveSix')
        case Seniority.ABOVE_SEVEN: return i18n.t('apply.experience.aboveSeven')
        case Seniority.ABOVE_EIGHT: return i18n.t('apply.experience.aboveEight')
        case Seniority.ABOVE_NINE: return i18n.t('apply.experience.aboveNine')
        case Seniority.ABOVE_TEN: return i18n.t('apply.experience.aboveTen')
        default: return ''
    }
}

const educationDisplay = (educations: Education[]) => {
    if (educations.length === 0) {
        return i18n.t('job.list.noWorkExperience')
    } else {
        return educations.map(e => 'us' === i18n.locale.value ? e.engName : e.name).join(', ')
    }
}
</script>
<style scoped>
.v-label {
    white-space: pre-line;
}

.delete-btn {
    color: #FFFFFF;
    background-color: #A5CF4C;
}

.word-wrap {
    white-space: pre-line;
}
</style>
