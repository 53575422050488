<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <htc-text-field v-model="lastName" max-length="100"
                    :rules="[v => validate.required(v, 'apply.personal.invalidate.lastName')]"
                    :label="$t('apply.personal.lastName')" class="required" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
            <v-col>
                <htc-text-field v-model="firstName" max-length="100"
                    :rules="[v => validate.required(v, 'apply.personal.invalidate.firstName')]"
                    :label="$t('apply.personal.firstName')" class="required" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <htc-text-field v-model="engName" max-length="100"
                    :rules="[v => validate.required(v, 'apply.personal.invalidate.englishName')]"
                    :label="$t('apply.personal.englishName')" class="required" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <htc-text-field v-model="mail" max-length="100"
                    placeholder="mail@example.com"
                    :rules="[v => validate.required(v, 'apply.personal.invalidate.email'),
                             v => validate.email(v, 'apply.personal.invalidate.emailFormat')]"
                    :label="$t('email')" class="required" clearable type="email">
                    <template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <htc-text-field v-model="phoneNo" max-length="100"
                    placeholder="0912345678, 0987-654-321, +886-955-555-555"
                    :rules="[v => validate.required(v, 'apply.personal.invalidate.phone'),
                             v => validate.regex(v, /^\+?[0-9-]+$/, 'apply.personal.invalidate.phoneFormat')]"
                    :label="$t('apply.personal.phone')" class="required" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </htc-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import HtcTextField from '@/components/HtcTextField.vue'
import { useApplyForm } from '@/store/applyForm'
import validate from '@/utils/validate'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'

const store = useApplyForm()
const { lastName, firstName, engName, mail, phoneNo } = storeToRefs(store)

watch([lastName, firstName, engName, mail, phoneNo], () => store.setLocalStorage('personal'))
</script>