import { ApiResponse, https } from "."

export interface DeleteResume {
    id: number
    chineseName: string
    englishName: string
    jobs: string[]
    createTimestamp: Date
}

export const send = (email: string) => {
    return https.post<ApiResponse<void>>('deleteResumes/send', { email, url: window.location.origin + window.location.pathname })
}
export const get = (token: string) => https.get<ApiResponse<DeleteResume[]>>(['deleteResumes', token].join('/'))
export const deleteResume = (token: string) => https.delete<ApiResponse<void>>(['deleteResumes', token].join('/'))
