<template>
    <v-container>
        <v-row v-if="store.getLocale() !== 'CN'">
            <v-col>
                <v-radio-group v-model="needWorkPermit" :label="$t('apply.other.isRequiredWorkPermit')"
                    :messages="'apply.other.isRequiredWorkPermitDesc'"
                    :rules="[v => required(v, 'apply.other.invalidate.isRequiredWorkPermit')]" inline class="required mb-4">
                    <v-radio :label="$t('yes')" :value="true"></v-radio>
                    <v-radio :label="$t('no')" :value="false"></v-radio>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-radio-group v-model="violatedIpRight" :label="$t('apply.other.hadViolatedIpRight')" inline>
                    <v-radio :label="$t('yes')" :value="true"></v-radio>
                    <v-radio :label="$t('no')" :value="false"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row v-if="violatedIpRight">
            <v-col>
                <htc-text-area v-model="violatedIpRightDesc" :label="$t('apply.other.hadViolatedIpRightDesc')"
                    max-length="1000" rows="5"></htc-text-area>
            </v-col>
        </v-row>
        <v-row>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <v-label class="mb-2">{{ $t('apply.other.recommender.tip') }}</v-label>
                        <v-expansion-panels v-model="panels" variant="accordion" multiple>
                            <v-expansion-panel v-for="(item, index) in recommenders" :key="index">
                                <v-expansion-panel-title @click="onClickPanel(index)">
                                    <span>{{ [$t('apply.other.recommender.title'), index + 1].join(' ') }}</span>
                                    <v-spacer></v-spacer>
                                    <v-icon v-if="recommenders.length > 1" @click.stop="remove(index)" color="#616264"
                                        class="mr-2">mdi-close-circle</v-icon>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <v-container>
                                        <v-row>
                                            <v-col sm="3">
                                                <htc-text-field v-model="item.name"
                                                    :label="$t('apply.other.recommender.name')"
                                                    clearable></htc-text-field>
                                            </v-col>
                                            <v-col sm="3">
                                                <htc-text-field v-model="item.title"
                                                    :label="$t('apply.other.recommender.jobTitle')"
                                                    clearable></htc-text-field>
                                            </v-col>
                                            <v-col sm="3">
                                                <htc-text-field v-model="item.company"
                                                    :label="$t('apply.other.recommender.company')"
                                                    clearable></htc-text-field>
                                            </v-col>
                                            <v-col sm="3">
                                                <htc-text-field v-model="item.phoneNo"
                                                    :label="$t('apply.other.recommender.tel')"
                                                    clearable></htc-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn @click="add">{{ $t('create') }}</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
        <v-row>
            <v-col>
                <v-file-input v-model="attachments" :label="$t('apply.other.uploadAttachment')"
                    messages="apply.other.uploadAttachmentDesc"
                    :rules="[v => required(v, 'apply.other.invalidate.uploadAttachment')]" clearable accept=".pdf,.docx"
                    class="required">
                    <template #message="{ message }">{{ $t(message) }}</template>
                </v-file-input>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-file-input v-model="portfolio" :label="$t('apply.other.uploadPortfolio')"
                    messages="apply.other.uploadPortfolioDesc" accept=".pdf,.docx" clearable>
                    <template #message="{ message }">{{ $t(message) }}</template>
                </v-file-input>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-toolbar color="#fff">
                    <v-label class="ml-2">{{ $t('apply.other.link') }}</v-label>
                    <v-btn @click="links.push('')" icon="mdi-plus" class="ml-2"></v-btn>
                </v-toolbar>
                <template v-for="(link, i) in links" :key="i">
                    <htc-text-field @update:model-value="onLinkUpdate(i, $event)" @click:append="onLinkClear(i)"
                        :model-value="link" max-length="100" placeholder="https://www.example.com" prepend-icon="mdi-link"
                        append-icon="mdi-close-circle">
                    </htc-text-field>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
import HtcTextField from '@/components/HtcTextField.vue'
import HtcTextArea from '@/components/HtcTextArea.vue'
import { useApplyForm } from '@/store/applyForm'
import { required } from '@/utils/validate'
import { storeToRefs } from 'pinia'
import { reactive, watch } from 'vue'

const store = useApplyForm()
const { needWorkPermit, violatedIpRight, violatedIpRightDesc, attachments, portfolio, links } = storeToRefs(store)
const recommenders = reactive(store.recommenders)
const panels = reactive(recommenders.map((e, i) => i))

watch([needWorkPermit, violatedIpRight, violatedIpRightDesc, recommenders, links], () => store.setLocalStorage('other'))

const onClickPanel = (index: number) => {
    if (panels.indexOf(index) > -1) {
        panels.splice(panels.indexOf(index), 1)
    } else {
        panels.push(index)
    }
}
const remove = (index: number) => {
    if (recommenders.length > 1) {
        recommenders.splice(index, 1)
        if (panels.indexOf(index) > -1) panels.splice(panels.indexOf(index), 1)
    }
}
const add = () => {
    if (recommenders.length >= 5) return
    recommenders.push({})
    panels.push(recommenders.length - 1)
}
const onLinkUpdate = (index: number, link: string) => {
    links.value[index] = link
    store.setLocalStorage('other')
}
const onLinkClear = (index: number) => {
    if (links.value.length > 1) {
        links.value.splice(index, 1)
    } else {
        links.value[index] = ''
    }
    store.setLocalStorage('other')
}

</script>
