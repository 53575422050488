<template>
    <v-dialog :model-value="modelValue" @update:model-value="onUpdate" width="auto" persistent>
        <v-card>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text>{{ text }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <slot name="action"></slot>
                <v-btn v-if="Boolean(confirmButton)" @click="onConfirm">{{ 'string' === typeof confirmButton ? confirmButton : $t('confirm') }}</v-btn>
                <v-btn v-if="Boolean(cancelButton)" @click="onCancel">{{ 'string' === typeof cancelButton ? cancelButton : $t('cancel') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script setup lang="ts">
withDefaults(defineProps<{
    modelValue: boolean
    title?: string
    text: string
    confirmButton?: boolean | string
    cancelButton?: boolean | string
}>(), {
    modelValue: true,
    title: undefined,
    confirmButton: true,
    cancelButton: true
})
const emit = defineEmits(['confirm', 'cancel', 'update:model-value'])
const onUpdate = (event: boolean) => {
    emit('update:model-value', event)
}
const onConfirm = () => {
    emit('confirm')
}
const onCancel = () => {
    emit('cancel')
    emit('update:model-value', false)
}
</script>
<style scoped>
.warnning {
    color: #FFCA28;
}
</style>
