export const required = (value: unknown, message?: string) => {
    let result: boolean
    if ('string' === typeof (value)) {
        result = Boolean(value)
    } else if (Array.isArray(value)) {
        result = value !== null && value !== undefined && value.length > 0
    } else {
        result = value !== null && value !== undefined
    }
    return result || (message ?? false)
}
export const email = (value: string, message?: string) => {
    const reg = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/
    return reg.test(value) || (message ?? false)
}
export const regex = (value: string, regex: RegExp, message?: string) => {
    return regex.test(value) || (message ?? false)
}
export default {
    required,
    email,
    regex
}